import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";
import TagManager from "react-gtm-module";
import dotenv from "dotenv";

dotenv.config();

const idNum = process.env.REACT_APP_GTM_ID;

const tagManagerArgs = {
  gtmId: idNum,
};

TagManager.initialize(tagManagerArgs);

export const Routes = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};
