const token = "token";

export function getToken() {
  const refreshToken = window.localStorage.getItem(token);
  return refreshToken;
}

export function setToken(value) {
  window.localStorage.setItem(token, value);
}

export function clearStorage() {
  window.localStorage.clear();
}
