import React from 'react';
import { OnboardingHeader } from '../../../components/onboarding/OnboardingHeader';
import { DeliveryTimesForm } from '../../../components/onboarding/tiendanube-only/DeliveryTimesForm';
import { fadeInAndRight } from '../../../services/shared/animation.service';
import { useAuthStore } from '../../../store';
import {motion} from 'framer-motion';

export const DeliveryTimes = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);

    return (
        <div className="container-fluid" id="deliveryTimes">
            <div className="row">
                <div className="col-4 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingHeader step={2} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-8 content-wrapper">
                   <DeliveryTimesForm ecommerce={ecommerce}/>
                </div>
            </div>
        </div>
    )
}
