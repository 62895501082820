import React from 'react'
import { CreateNewCenter } from '../../../components/onboarding/vtex-only/CreateNewCenter';
import { useAuthStore } from '../../../store';
import {motion} from 'framer-motion';
import { fadeInAndUp, fadeInAndUpNoDelay } from '../../../services/shared/animation.service';

export const CreateCenterConfig = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const user = useAuthStore((state) => state.authData.user);

    return (
        <div id="distributionCenterConfig">
            <motion.div variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                <h1 className="step-title fw-bold">Creación de muelle</h1> 
                <CreateNewCenter ecommerce={ecommerce} />
            </motion.div>
        </div>
    )
}