import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { configLocation } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { validateInputs } from '../../../services/shared/utils.service';
import { useAuthStore, useonboardingStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { InputsWithTooltip } from '../../units/Inputs';
import {motion} from 'framer-motion';
import { customerRouteName } from '../../../data/InfoSource';
import { childVariants, fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../../services/shared/animation.service';

export const CreationForm = ({ ecommerce }) => {
  let history = useHistory();
  const auth = useAuthStore((state) => state.authData);
  const store = useonboardingStore((state) => state.onboardingData.store.locations[0]);
  const addData = useonboardingStore((state) => state.addData);

  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [body, setBody] = useState({
    city: store?.city || "",
    province: store?.province || "",
    postalCode: store?.postalCode || "",
    street: store?.street || "",
    externalNumber: store?.externalNumber || "",
    // internalNumber: store?.internalNumber || "",
    userId: store?.userId || "",
    createAt: store?.createAt || "",
    typeOfFee: store?.typeOfFee || "",
    fee: store?.fee || ""
  });
  
  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
  };

  const handleCreation = async (e) => {
    e.preventDefault();
    if (validateInputs(body, setFormErrors)) {
      let toSend = {locations: [ {
        city: body.city,
        province: body.province,
        postalCode: body.postalCode,
        street: body.street,
        externalNumber: body.externalNumber,
        // internalNumber: body.internalNumber,
      } ]};
      let toSave = {locations: [ { ...body } ]};
      setIsLoading(true)
      try {
        const result = await configLocation(toSend, auth.user.storeId);
        if (result && result.success) {
          addData({
            store: {
              ...toSave
            },
          });
          showSuccess(result.message);
          if (auth.user.insidePanel) {
            history.push(`/${customerRouteName}/${ecommerce}/panel/location-list`);
          } else {
            history.push(`/${customerRouteName}/${ecommerce}/rate`);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showError(error);
      }
    } else {
      return
    }
  };

  return (
    <form className="col w-50 mt-3 mt-xxl-5" onSubmit={handleCreation}>
      <motion.div className="d-flex flex-column justify-content-between" variants={auth.user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
        <motion.div variants={childVariants} className="mb-1 mb-xxl-3 w-100">
          <InputsWithTooltip
            value={body.street}
            id="street"
            type="text"
            labelTxt="Calle*"
            onChangeFn={handleChange}
            resultValidation={formErrors?.street ? "is-invalid" : ""}
          />
          {formErrors.street && <span className="invalid-msg">{formErrors.street}</span>}
        </motion.div>
        <motion.div variants={childVariants} className="mb-1 mb-xxl-3 w-100">
          <InputsWithTooltip
            value={body.externalNumber}
            id="externalNumber"
            type="text"
            labelTxt="Número exterior*"
            onChangeFn={handleChange}
            resultValidation={formErrors?.externalNumber ? "is-invalid" : ""}
          />
          {formErrors.externalNumber && <span className="invalid-msg">{formErrors.externalNumber}</span>}
        </motion.div>
        {/* <motion.div variants={childVariants} className="mb-1 mb-xxl-3 w-100">
          <InputsWithTooltip
            value={body.internalNumber}
            id="internalNumber"
            type="text"
            labelTxt="Número interior / Departamento"
            onChangeFn={handleChange}
            resultValidation={formErrors?.internalNumber ? "is-invalid" : ""}
          />
          {formErrors.internalNumber && <span className="invalid-msg">{formErrors.internalNumber}</span>}
        </motion.div> */}
        <motion.div variants={childVariants} className="mb-1 mb-xxl-3 w-100">
          <InputsWithTooltip
            value={body.city}
            id="city"
            type="text"
            labelTxt="Ciudad*"
            onChangeFn={handleChange}
            resultValidation={formErrors?.city ? "is-invalid" : ""}
          />
          {formErrors.city && <span className="invalid-msg">{formErrors.city}</span>}
        </motion.div>

        <motion.div variants={childVariants} className="mb-1 mb-xxl-3 w-100">
          <InputsWithTooltip
            value={body.province}
            id="province"
            type="text"
            labelTxt={auth.user.country === "MX" ? "Estado*" : "Provincia*"}
            onChangeFn={handleChange}
            resultValidation={formErrors?.province ? "is-invalid" : ""}
          />
          {formErrors.province && <span className="invalid-msg">{formErrors.province}</span>}
        </motion.div>

        <motion.div variants={childVariants} className="mb-1 mb-xxl-3 w-100">
          <InputsWithTooltip
            value={body.postalCode}
            id="postalCode"
            type="text"
            labelTxt="Código postal*"
            onChangeFn={handleChange}
            resultValidation={formErrors?.postalCode ? "is-invalid" : ""}
          />
          {formErrors.postalCode && <span className="invalid-msg">{formErrors.postalCode}</span>}
        </motion.div>
      </motion.div>

      <motion.div className="bottomPage" variants={growBtn} initial="hidden" animate="visible">
        <ButtonSmall
          ecommerce={ecommerce}
          type="submit"
          btnTxt={auth.user.insidePanel ? "Guardar" : "Siguiente"}
          showSpinner={isLoading}
          isDisabled={(body.city && body.province && body.postalCode && body.street && body.externalNumber) ? false : true}
        />
      </motion.div>
    </form>
  );
};
