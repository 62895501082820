import React, { useState } from 'react';
import { useLocation } from "react-router";
import { setEcommerceURL } from '../../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { IntroColumn } from '../../../components/onboarding/IntroColumn';
import { VtexIntegrationForm } from '../../../components/onboarding/vtex-only/VtexIntegrationForm';
import { fadeInAndUpNoDelay } from '../../../services/shared/animation.service';
import { IntegrationTypeSelect } from '../../../components/onboarding/vtex-only/IntegrationTypeSelect';

export const VtexKeysConfig = () => {
  const location = useLocation();
  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
  const [typeSelected, setTypeSelected] = useState(false);

  return (
    <div className="container-fluid" id="welcome">
      <div className="row">
        <IntroColumn ecommerce={ecommerce} />
        <div className="col col-xxl-8 form-col">
          {!typeSelected ? ( // First we ask user to select integration type
            <IntegrationTypeSelect 
              setTypeSelected={setTypeSelected} 
              ecommerce={ecommerce} 
            />
          ) : ( // We proceed with rest of onboarding flow
            <motion.div className="form-wrapper" variants={fadeInAndUpNoDelay} initial="hidden" animate="visible">
              <h2 className="small-title fw-bold">
                Crear cuenta - VTEX
              </h2>
              <VtexIntegrationForm ecommerce={ecommerce} />
            </motion.div>
          )}
        </div>
      </div>
    </div>
  )
}