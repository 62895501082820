import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { changeShopifyName, noSpacedNameShortener } from "../../services/shared/utils.service";
import { InputsSwitch } from "../units/Inputs";
import chevronFw from '../../assets/chevronFw.png';
import chevronFwRed from '../../assets/chevronFwRed.png';
import lineRed from '../../assets/lineRed.png';
import { FiAlertTriangle } from "react-icons/fi";

import { customerRouteName } from '../../data/InfoSource';

export const LocationItem = ({ index, ecommerce, id, checked, handleChange, insidePanel, ...location }) => {

  const [isConfigurated, setIsConfigurated] = useState(false);
  
  useEffect(() => {
    if(location.firstName && location.lastName && location.phoneNumber && location.email){
      setIsConfigurated(true)
    }
  }, [])

  return (
    <div className="locationItem-grid mb-3">
        <InputsSwitch
        id={id}
        value
        checked={checked}
        onChangeFn={handleChange}
        labelTxt={location.name ? noSpacedNameShortener(location.name, 31) : `Tienda ${index}`}
        />
        <div className="address-wrapper">
            <h3 className="step-subtitle">{((location.address && location.city) && (!location.address.includes("null") && !location.city.includes("null"))) ? `${location.address}, ${location.city}.` : "- Dirección incompleta -"}</h3>
            {location.active && 
              <small className={isConfigurated ? "success" : ""}>{isConfigurated ? `Tienda configurada` : `Configuración incompleta`}</small>
            }
        </div>
        {location.active && 
          <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list/${id}`} className="step-subtitle">Configurar <img src={chevronFw} alt=""/></Link>
        }
    </div>
  );
};

export const LocationItemError = ({ index, ecommerce, id, insidePanel, ...location }) => {
  return (
    <div className="locationItem-grid danger mb-3">
        <div className="noSwitch">
          <img src={lineRed} alt="" />
          <h4 className="form-check-label label-bold">{location.name ? noSpacedNameShortener(location.name, 31) : `Tienda ${index}`}</h4>
        </div>
        <div className="address-wrapper">
            <h3 className="step-subtitle">{((location.address && location.city) && (!location.address.includes("null") && !location.city.includes("null"))) ? `${location.address}, ${location.city}.` : "- Dirección incompleta -"}</h3>
        </div>
        <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list/${id}`} className="step-subtitle">Ver <img src={chevronFwRed} alt=""/></Link>  
    </div>
  );
};

export const LocationItemNotConfiguredInEcommerce = ({ index, ...location }) => {
  return (
    <div className="locationItem-grid warning mb-3">
        <div className="noSwitch">
          <FiAlertTriangle size={20} />
          <h4 className="form-check-label label-bold">{location.name ? noSpacedNameShortener(location.name, 31) : `Tienda ${index}`}</h4>
        </div>
        <div className="address-wrapper">
            <h3 className="step-subtitle">{((location.address && location.city) && (!location.address.includes("null") && !location.city.includes("null"))) ? `${location.address}, ${location.city}.` : "- Dirección incompleta -"}</h3>
            <small>Debes terminar la configuración de esta tienda en Shopify</small>
        </div>
    </div>
  );
};