import React from 'react';
import { DeliveryPromiseForm } from '../../components/onboarding/DeliveryPromiseForm';
import { OnboardingHeader } from '../../components/onboarding/OnboardingHeader';
import { fadeInAndRight } from '../../services/shared/animation.service';
import { useAuthStore } from '../../store';
import {motion} from 'framer-motion';

export const DeliveryPromise = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);

    return (
        <div className="container-fluid" id="deliveryPromise">
            <div className="row">
                <div className="col-4 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingHeader step={2} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-8 content-wrapper">
                    {/* <h1 className="step-title fw-bold">Promesa de Entrega</h1>
                    <h2 className="step-subtitle">Defina los tiempos de preparación de los envíos para cada uno de los centros activados</h2> */}

                    <DeliveryPromiseForm ecommerce={ecommerce} />
                </div>
            </div>
        </div>
    )
}
