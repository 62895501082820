import React, { useEffect, useState } from 'react';
import { useAuthStore } from '../../store';
import { getPickupPointsMap } from '../../services/panel.service';
import Skeleton from 'react-loading-skeleton';
import { showError } from '../../services/shared/alert.service';

export const RenderMap = ({ type = 'pickup' }) => {
  const getAuthData = useAuthStore((state) => state.getAuthData);
  const [isLoading, setIsLoading] = useState(true);
  const [ulrMap, setUlrMap] = useState(null);

  const getPoints = async () => {
    try {
      const { user } = getAuthData();
      const { success, url } = await getPickupPointsMap(user?.storeId, type);
      if (success) {
        setUlrMap(url);
      }
      setIsLoading(false);
    } catch (error) {
      showError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPoints();
  }, [type]);

  return (
    <>
      {!isLoading && ulrMap ? (
        <iframe src={ulrMap} title="Map" width="100%" height="100%"></iframe>
      ) : (
        <Skeleton count={1} height={20} />
      )}
    </>
  );
};
