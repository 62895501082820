import axios from "axios";
import { getToken } from "./storage";

export function configAxiosRequest({ user, accessToken }) {
  axios.interceptors.request.use(
    (request) => {
      const token = getToken();
      if (token) {
        request.headers["authorization"] = `Bearer ${token}`;
      }
      if (user && user?.accessToken) {
        request.headers["authorization"] = `Bearer ${user.accessToken}`;
      }
      if (accessToken) {
        request.headers["authorization"] = `Bearer ${accessToken}`;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export function configAxiosResponse({
  ecommerce,
  removeVtexAuthMinusEcommerce,
  showError,
  history,
  customerRouteName,
}) {
  axios.interceptors.response.use(
    undefined,
    function axiosRetryInterceptor(err) {
      if (ecommerce === "vtex" && err?.response?.status == 401) {
        removeVtexAuthMinusEcommerce();
        history.push(`/${customerRouteName}/vtex/sign-in`);
        showError("Expiró el token", 3000);
      }
      if (
        ecommerce === "woo" &&
        (err?.response?.status === 500 ||
          err?.response?.status === 401 ||
          err?.response?.status === 403)
      ) {
        showError("Expiró el token", 3000);
      }
      return Promise.reject(err);
    }
  );
}
