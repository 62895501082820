import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";
import { useAuthStore } from "../../store";
import {
  capitalizeFirstLetter,
  setEcommerceURL,
} from "../../services/shared/utils.service";
import { motion } from "framer-motion";
import { fadeInAndRight } from "../../services/shared/animation.service";

import { customerName, customerRouteName } from "../../data/InfoSource";
import { signInWoo } from "../../services/onboarding.service";
import { showErrorWoo } from "../../services/shared/alert.service";
import corner from "../../assets/tableCorner.svg";
import introIllustration from "../../assets/introIllustration.png";
import loaderpickit from "../../assets/loader-pickit.gif";
import { setToken } from "../../services/storage";
import { configAxiosRequest } from "../../services/axios";

export const Loader = () => {
  const location = useLocation();
  let history = useHistory();
  const addAuth = useAuthStore((state) => state.addAuthData);
  const user = useAuthStore((state) => state.authData.user);

  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));

  const { accessToken = "" } = queryString.parse(location.search);

  const flowLogin = async () => {
    if (user?.insidePanel) {
      addAuth({
        user: {
          ...user,
          insidePanel: false,
        },
      });
    }
    document.title = `${capitalizeFirstLetter(
      customerName
    )} - ${capitalizeFirstLetter(ecommerce)}`;

    if (accessToken) {
      try {
        const records = await signInWoo(accessToken);
        const { token, store, type, isPostPay, typePyment } = records;
        setToken(token);
        configAxiosRequest({ accessToken: token });
        addAuth({
          user: {
            ...user,
            accessToken: token,
            typeToken: type,
            store,
            isPostPay,
            typeOfMerchant: typePyment,
          },
        });
        history.push(`/${customerRouteName}/woo/panel/order/all`);
      } catch (error) {
        addAuth({
          user: {
            accessToken: "",
          },
        });
        showErrorWoo("Error en credenciales", error);
      }
    } else {
      addAuth({
        user: {
          accessToken: "",
        },
      });
      showErrorWoo("Error en credenciales", "Token no válido");
    }
  };

  useEffect(() => {
    flowLogin();
  }, [accessToken]);

  return (
    <div className="container-fluid" id="welcome">
      {accessToken ? (
        <>
          <div className="corner-background">
            <img src={corner} alt="" />
          </div>
          <div className="row">
            <div className="col col-12 form-col">
              <motion.div
                className="form-wrapper"
                variants={fadeInAndRight}
                initial="hidden"
                animate="visible"
              >
                <h2 className="fw-bold">Iniciando sesión.</h2>
                <p>Por favor espere...</p>
                <img
                  className="loader-woo-img"
                  src={introIllustration}
                  alt=""
                />
                <img className="loader-woo-gif" src={loaderpickit} alt="" />
              </motion.div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
