import React from 'react';
import { motion } from 'framer-motion';
import { childVariants } from '../../services/shared/animation.service';
import { useAuthStore } from '../../store';

export const OnboardingNav = ({ step, ecommerce }) => {
    const user = useAuthStore(state => state.authData.user);
    
    const pages = ["Tus tiendas", "Tarifa", "Guardado"];
    const pagesTiendanube = ["Tu tienda", "Tarifa y creación", "Guardado"];
    const pagesVtex = ["Tus tiendas", "Guardado"];
    
    return (
      <div className="col-6 onboarding-nav">
        {ecommerce === "vtex" && user.fullIntegration && (
          <ul className="pages-titles-list">
            {pagesVtex.map((page, index) => (
              <motion.li variants={childVariants} key={index} className={index + 1 === step ? "selected" : ""}>
                <div className="circle"></div>
                <span>{page}</span>
              </motion.li>
            ))}
          </ul>
        )}
        {ecommerce === "shopify" && (
          <ul className="pages-titles-list">
            {pages.map((page, index) => (
              <motion.li variants={childVariants} key={index} className={index + 1 === step ? "selected" : ""}>
                <div className="circle"></div>
                <span>{page}</span>
              </motion.li>
            ))}
          </ul>
        )}
        {ecommerce === "tiendanube" && (
          <ul className="pages-titles-list">
            {pagesTiendanube.map((page, index) => (
              <motion.li variants={childVariants} key={index} className={index + 1 === step ? "selected" : ""}>
                <div className="circle"></div>
                <span>{page}</span>
              </motion.li>
            ))}
          </ul>
        )}
      </div>
    );
}
