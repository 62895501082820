import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { saveClientInfo } from "../../services/panel.service";
import { showError, showSuccess } from "../../services/shared/alert.service";
import { ButtonSmall } from "../units/Button";
import { InputsTextarea, InputsWithTooltip } from "../units/Inputs";
import { motion } from 'framer-motion';
import { childVariants, fadeInAndUpNoDelay, growBtnSmallDelay } from "../../services/shared/animation.service";
import { validateInputs } from "../../services/shared/utils.service";

export const ModalEdit = ({ orderId, storeId, tabSelected, updateTable, handleClose, orderDetail, ecommerce }) => {
  const [isLoading, setIsLoding] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [body, setBody] = useState({
    firstName: orderDetail?.firstName,
    lastName: orderDetail?.lastName,
    city: orderDetail?.city,
    province: orderDetail?.province,
    street: orderDetail?.street,
    postalCode: orderDetail?.postalCode,
    email: orderDetail?.email,
    phoneNumber: orderDetail?.phoneNumber,
    notes: orderDetail?.notes,
    country: orderDetail?.country,
  });

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
  }

  const handleSubmit = async () => {
    if (validateInputs(body, setFormErrors)) {
      setIsLoding(true);
      let composedBody = {
        orderId: orderId,
        updatedInfo: {
          ...body
        },
      };
      try {
        const result = await saveClientInfo(composedBody, storeId);
        if (result && result.success) {
          showSuccess(result.message);
          setTimeout(() => {
            updateTable(storeId, "all")
          }, 500);
        }
        if (!result.success) {
          showError(result?.message);
        }
      } catch (error) {
        showError(error)
      }
      handleClose();
      setIsLoding(false);
    } else {
      return
    }
  };

  return (
    <motion.div variants={fadeInAndUpNoDelay} initial="hidden" animate="visible">
      <Modal
        className="rounded-3"
        id="panel-edit-modal"
        show={true}
        backdrop={true}
        onHide={handleClose}
        centered
        size="lg"
        scrollable
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-title medium-text">Editar información</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body columns">
          <div>
            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                value={body.firstName}
                id="firstName"
                type="text"
                labelTxt="Nombre del cliente*"
                onChangeFn={handleChange}
                resultValidation={formErrors?.firstName ? "is-invalid" : ""}
                disabled={false}
              />
              {formErrors.firstName && <span className="invalid-msg">{formErrors.firstName}</span>}
            </motion.div>

            {(ecommerce === "shopify" || ecommerce === "vtex")
              &&
              <motion.div variants={childVariants} className="mb-1 w-100">
                <InputsWithTooltip
                  value={body.lastName}
                  id="lastName"
                  type="text"
                  labelTxt="Apellido del cliente*"
                  onChangeFn={handleChange}
                  resultValidation={formErrors?.lastName ? "is-invalid" : ""}
                  disabled={false}
                />
                {formErrors.lastName && <span className="invalid-msg">{formErrors.lastName}</span>}
              </motion.div>
            }

            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                value={body.city}
                id="city"
                type="text"
                labelTxt="Ciudad*"
                onChangeFn={handleChange}
                resultValidation={formErrors?.city ? "is-invalid" : ""}
                disabled={true}
              />
              {formErrors.city && <span className="invalid-msg">{formErrors.city}</span>}
            </motion.div>

            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                value={body.province}
                id="province"
                type="text"
                labelTxt={body.country === "MX" ? "Estado*" : "Provincia*"}
                onChangeFn={handleChange}
                resultValidation={formErrors?.province ? "is-invalid" : ""}
                disabled={true}
              />
              {formErrors.province && <span className="invalid-msg">{formErrors.province}</span>}
            </motion.div>

            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                value={body.street}
                id="street"
                type="text"
                labelTxt="Calle*"
                onChangeFn={handleChange}
                resultValidation={formErrors?.street ? "is-invalid" : ""}
                disabled={true}
              />
              {formErrors.street && <span className="invalid-msg">{formErrors.street}</span>}
            </motion.div>
          </div>
          <div>
            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                value={body.postalCode}
                id="postalCode"
                type="text"
                labelTxt="Código postal*"
                onChangeFn={handleChange}
                resultValidation={formErrors?.postalCode ? "is-invalid" : ""}
                disabled={true}
              />
              {formErrors.postalCode && <span className="invalid-msg">{formErrors.postalCode}</span>}
            </motion.div>

            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                value={body.email}
                id="email"
                type="text"
                labelTxt={ecommerce === "tiendanube" ? "Correo electrónico" : "Correo electrónico*"}
                placeholder="Ej: email@example.com"
                onChangeFn={handleChange}
                resultValidation={formErrors?.email ? "is-invalid" : ""}
                disabled={false}
              />
              {formErrors.email && <span className="invalid-msg">{formErrors.email}</span>}
            </motion.div>

            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                value={body.phoneNumber}
                id="phoneNumber"
                type="text"
                labelTxt="Teléfono*"
                onChangeFn={handleChange}
                resultValidation={formErrors?.phoneNumber ? "is-invalid" : ""}
                disabled={false}
              />
              {formErrors.phoneNumber && <span className="invalid-msg">{formErrors.phoneNumber}</span>}
            </motion.div>

            <motion.div variants={childVariants} className="mx-1 w-100">
              <InputsTextarea
                value={body.notes}
                id="notes"
                placeholder="Ej. El timbre no funciona."
                labelTxt="Observaciones"
                onChangeFn={handleChange}
                rows={ecommerce === "tiendanube" ? 1 : 4}
                maxLength={900}
                isDisabled={false}
              />
              {formErrors.notes && <span className="invalid-msg">{formErrors.notes}</span>}
            </motion.div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <motion.div variants={growBtnSmallDelay}>
            <ButtonSmall
              ecommerce={ecommerce}
              type="button"
              btnTxt="Cerrar"
              showSpinner={false}
              isDisabled={false}
              onClickFn={() => handleClose()}
              extraClass="btn btn-outline-secondary"
            />
          </motion.div>

          <motion.div variants={growBtnSmallDelay}>
            <ButtonSmall
              ecommerce={ecommerce}
              type="button"
              btnTxt="Guardar"
              showSpinner={isLoading ? true : false}
              isDisabled={(((ecommerce === "shopify" || ecommerce === "vtex") && body.firstName && body.lastName && body.email && body.phoneNumber) || ((ecommerce === "tiendanube") && body.firstName && body.phoneNumber)) ? false : true}
              onClickFn={() => handleSubmit()}
            />
          </motion.div>
        </Modal.Footer>
      </Modal>
    </motion.div>
  );
};
