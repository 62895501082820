import React, { useEffect, useState } from 'react'
import { ButtonSmall } from '../../components/units/Button';
import { InputsCheckbox, InputsRadio } from '../../components/units/Inputs';
import { getOrdersSetting, setOrdersSetting } from '../../services/panel.service';
import { showError, showSuccess } from '../../services/shared/alert.service';
import { useAuthStore } from '../../store';
import { customerName } from '../../data/InfoSource';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const Settings = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const auth = useAuthStore((state) => state.authData);
    const [isLoading, setIsLoading] = useState(true);
    const [allOrdersSelected, setAllOrdersSelected] = useState("allOrders");
    const [withdrawSelection, setWithdrawSelection] = useState({
        acceptHomeDelivery: true,
        acceptPickitPoints: true
    });

    const handleRadioChange = (e) => {
        setAllOrdersSelected(e.target.value);
    }

    const handleCheckboxes = (e) => {
        setWithdrawSelection({ ...withdrawSelection, [e.target.id]: e.target.checked });
    }

    useEffect(async () => {
        setIsLoading(true);
        try {
            const recoverSettings = await getOrdersSetting(auth.user.storeId);
            if (recoverSettings && recoverSettings.success) {
                if (recoverSettings?.configuration?.catchAllOrders) {
                    setAllOrdersSelected("allOrders")
                } else {
                    setAllOrdersSelected("onlyCustomerMethod")
                }
                setWithdrawSelection({
                    acceptHomeDelivery: recoverSettings?.configuration?.acceptHomeDelivery,
                    acceptPickitPoints: recoverSettings?.configuration?.acceptPickitPoints
                })
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }, [])

    const setSettings = async (e) => {
        e.preventDefault();
        if (allOrdersSelected) {
            setIsLoading(true);
            let body = {
                "configuration": {
                    "catchAllOrders": allOrdersSelected === "allOrders" ? true : false,
                    "acceptHomeDelivery": withdrawSelection.acceptHomeDelivery,
                    "acceptPickitPoints": withdrawSelection.acceptPickitPoints
                }
            }
            try {
                const result = await setOrdersSetting(body, auth.user.storeId);
                if (result && result.success) {
                    showSuccess(result.message);
                }
                if (result?.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                showError(error);
                setIsLoading(false);
            }
        }
    }


    return (
        <div id="settings">
            <h1 className="step-title fw-bold">Órdenes dentro del panel</h1>
            <h2 className="step-subtitle">Selecciona cuáles son las órdenes por defecto que quieres ver en tu panel.</h2>

            {isLoading ?
                <div>
                    <Skeleton count={1} height={90} style={{ marginBottom: "10px" }} />
                </div>
                :
                <form onSubmit={setSettings}>
                    <div className='mt-3 mt-xxl-4 mb-5'>
                        <div className='radio-setting'>
                            <InputsRadio
                                id="allOrders"
                                name="allOrdersSelected"
                                value="allOrders"
                                labelTxt="Todas las órdenes."
                                onChangeFn={handleRadioChange}
                                checked={allOrdersSelected === "allOrders"}
                            />
                            <p>Sin importar el método de envío.</p>
                        </div>
                        <div className='radio-setting'>
                            <InputsRadio
                                id="onlyCustomerMethod"
                                name="allOrdersSelected"
                                value="onlyCustomerMethod"
                                labelTxt={`Órdenes de ${customerName}.`}
                                onChangeFn={handleRadioChange}
                                checked={allOrdersSelected === "onlyCustomerMethod"}
                            />
                            <p>Aquellas que se hayan realizado con este método de entrega.</p>
                        </div>
                    </div>

                    <div className='mt-4 mt-xxl-5'>
                    <h2 className="step-subtitle mb-3 mb-xxl-4">Selecciona qué forma o formas de entrega quieres ver en tu checkout.</h2>
                        <InputsCheckbox
                            id="acceptHomeDelivery"
                            value={withdrawSelection.acceptHomeDelivery}
                            checked={withdrawSelection.acceptHomeDelivery === true}
                            onChangeFn={handleCheckboxes}
                            labelTxt="A domicilio."
                            disabled={false}
                        />
                        <InputsCheckbox
                            id="acceptPickitPoints"
                            value={withdrawSelection.acceptPickitPoints}
                            checked={withdrawSelection.acceptPickitPoints === true}
                            onChangeFn={handleCheckboxes}
                            labelTxt="Punto de retiro."
                            disabled={false}
                        />
                    </div>
                    <div className="bottomPage">
                        <ButtonSmall
                            ecommerce={ecommerce}
                            type="submit"
                            btnTxt="Guardar"
                            showSpinner={isLoading}
                            isDisabled={(allOrdersSelected && (withdrawSelection.acceptHomeDelivery || withdrawSelection.acceptPickitPoints)) ? false : true}
                        />
                    </div>
                </form>
            }
        </div>
    )
}
