import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ButtonSmall } from '../units/Button';
import { InputsTextarea, InputsWithTooltip } from '../units/Inputs';
import { showError, showSuccess } from '../../services/shared/alert.service';
import { useAuthStore, useonboardingStore } from '../../store';
import { changeShopifyName, validateInputs } from '../../services/shared/utils.service';
import { motion } from 'framer-motion';

import { customerRouteName } from '../../data/InfoSource';
import { childVariants, fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../services/shared/animation.service';
import { saveDataVtexShopi } from '../../services/onboarding.service';

export const LocationForm = ({ ecommerce, singleLocationIndex, allLocations, insidePanel, ...singleLocation }) => {
    let history = useHistory();
    const user = useAuthStore((state) => state.authData.user);
    const addData = useonboardingStore((state) => state.addData);
    const generalSwitch = useonboardingStore((state) => state.onboardingData.store?.activeAllSwitch);
    const nextRouteParam = user?.insidePanel ? "panel/location-list" : "location-list";

    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const [currentLocations, setCurrentLocations] = useState(allLocations)

    const [body, setBody] = useState({
        firstName: singleLocation?.firstName,
        lastName: singleLocation?.lastName,
        phoneNumber: singleLocation?.phoneNumber,
        notes: singleLocation?.notes,
        email: "",
        zip: singleLocation?.zip,

        active: true,
        address: singleLocation?.address,
        address2: singleLocation?.address2,
        locationId: singleLocation?.locationId,
        city: singleLocation?.city,
        country: singleLocation?.country,
        country_code: singleLocation?.country_code,
        ecommerceConfigured: singleLocation?.ecommerceConfigured || true,
        inCoverage: singleLocation?.inCoverage,
        name: singleLocation?.name,
        province: singleLocation?.province,
    })

    useEffect(() => {
        if (singleLocation.email) {
            setBody({ ...body, email: singleLocation.email })
        } else {
            setBody({ ...body, email: user?.email })
        }
    }, [])

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: e.target.value });
    }

    const setConfiguration = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            setCurrentLocations(currentLocations.locations[singleLocationIndex] = body);
            if (user.insidePanel || ecommerce==="vtex") {
                try {
                    const result = await saveDataVtexShopi(currentLocations, user.storeId);
                    if (result && result.success) {
                        showSuccess(result.message);
                        history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParam}`);
                    }
                    if (result?.error) {
                        showError(result.error);
                    }
                } catch (error) {
                    setIsLoading(false);
                    showError(error);
                }
            } else {
                addData({
                    store: {
                        activeAllSwitch: generalSwitch || false,
                        currentlocations: currentLocations
                    },
                });
                showSuccess("Configuración exitosa");
                history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/location-list`);
            }
            setIsLoading(false);
        } else {
            return
        }
    }

    return (
        <form onSubmit={setConfiguration}>
            <motion.div className="single-loc-form" variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                <div className="form-grid-two-columns mt-3 pb-0">
                    <motion.div variants={childVariants} className="mb-1 w-100">
                        <InputsWithTooltip
                            value={body.firstName}
                            id="firstName"
                            type="text"
                            labelTxt="Nombre de quien prepara los envíos*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.firstName ? "is-invalid" : ""}
                        />
                        {formErrors.firstName && <span className="invalid-msg">{formErrors.firstName}</span>}
                    </motion.div>

                    <motion.div variants={childVariants} className="mb-1 w-100">
                        <InputsWithTooltip
                            value={body.lastName}
                            id="lastName"
                            type="text"
                            labelTxt="Apellido de quien prepara los envíos*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.lastName ? "is-invalid" : ""}
                        />
                        {formErrors.lastName && <span className="invalid-msg">{formErrors.lastName}</span>}
                    </motion.div>
                </div>
                <div className="form-grid-two-columns pb-0">
                    <motion.div variants={childVariants} className="mb-1 w-100">
                        <InputsWithTooltip
                            value={body.phoneNumber}
                            id="phoneNumber"
                            type="text"
                            labelTxt="Teléfono*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.phoneNumber ? "is-invalid" : ""}
                        />
                        {formErrors.phoneNumber && <span className="invalid-msg">{formErrors.phoneNumber}</span>}
                    </motion.div>

                    <motion.div variants={childVariants} className="mb-1 w-100">
                        <InputsWithTooltip
                            value={body.email}
                            id="email"
                            type="text"
                            labelTxt="Correo electrónico*"
                            placeholder="Ej: email@example.com"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.email ? "is-invalid" : ""}
                        />
                        {formErrors.email && <span className="invalid-msg">{formErrors.email}</span>}
                    </motion.div>
                </div>
                <div className={ecommerce === "vtex" ? "form-grid-two-columns pb-0" : "pt-2 pb-0"}>
                    {ecommerce === "vtex" &&
                        <motion.div variants={childVariants} className="mb-1 w-100">
                            <InputsWithTooltip
                                value={body.zip}
                                id="zip"
                                type="text"
                                labelTxt="Código postal*"
                                onChangeFn={handleChange}
                                resultValidation={formErrors?.zip ? "is-invalid" : ""}
                            />
                            {formErrors.zip && <span className="invalid-msg">{formErrors.zip}</span>}
                        </motion.div>
                    }

                    <motion.div variants={childVariants} className="mb-1 w-100">
                        <InputsTextarea
                            value={body.notes}
                            id="notes"
                            placeholder="Ej. La tienda se encuentra en un segundo piso"
                            labelTxt="Notas o Instrucciones para el retiro*"
                            onChangeFn={handleChange}
                            rows={ecommerce === "vtex" ? 1 : 4}
                            maxLength={900}
                        />
                        {formErrors.notes && <span className="invalid-msg">{formErrors.notes}</span>}
                    </motion.div>

                </div>
            </motion.div>
            <div className="bottomPage doubleBtn">
                <motion.div variants={growBtn} initial="hidden" animate="visible">
                    <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list`} className="step-subtitle fw-bold btn btn-link">Atrás</Link>
                </motion.div>

                <motion.div variants={growBtn} initial="hidden" animate="visible">
                    <ButtonSmall
                        ecommerce={ecommerce}
                        type="submit"
                        btnTxt="Configurar"
                        showSpinner={isLoading}
                        isDisabled={((ecommerce === "vtex" && body.firstName && body.lastName && body.phoneNumber && body.email && body.notes && body.zip) || (ecommerce !== "vtex" && body.firstName && body.lastName && body.phoneNumber && body.email && body.notes)) ? false : true}
                    />
                </motion.div>
            </div>
        </form>
    )
}
