import React from 'react'

export const ButtonBig = ({ ecommerce, type, btnTxt, showSpinner, isDisabled, onClickFn, extraClass }) => {
    return (
        <div className="d-grid gap-2">
            {isDisabled ?
                <button type={type} className={`btn btn-primary ${ecommerce} mt-3 mt-xxl-4 mb-3 ${extraClass}`} onClick={onClickFn} disabled>
                    {showSpinner ?
                        <div className="spinner-grow spinner-grow-sm" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div>
                        :
                        btnTxt
                    }
                </button>
                :
                <button type={type} className={`btn btn-primary ${ecommerce} mt-3 mt-xxl-4 mb-3 ${extraClass}`} onClick={onClickFn}>
                    {showSpinner ?
                        <div className="spinner-grow spinner-grow-sm" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div>
                        :
                        btnTxt
                    }
                </button>
            }
        </div>
    )
}

export const ButtonSmall = ({ ecommerce, type, btnTxt, showSpinner, isDisabled, onClickFn, extraClass, icon }) => {
    return (
        <div className="d-flex justify-content-end align-items-center">
            <button type={type} className={`btn btn-primary ${ecommerce} small ${extraClass}`} onClick={onClickFn} disabled={isDisabled}>
                {showSpinner ?
                    <div className="spinner-grow spinner-grow-sm" role="status">
                        <span className="visually-hidden">Cargando...</span>
                    </div>
                    :
                    <>
                    {icon} {btnTxt}
                    </>
                }
            </button>
        </div>
    )
}
