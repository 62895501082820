import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useAuthStore } from "../../store";

import "react-loading-skeleton/dist/skeleton.css";
import { GridSetterWoo } from "../../components/panel/GridSetterWoo";

// import { mockOrders } from '../../data/mockData'

export const GridContainerWoo = ({ ecommerce, shop, store }) => {
  const user = useAuthStore((state) => state.authData.user);
  const [tabSelected, setTabSelected] = useState("Todas");

  const specialTabsNames = {
    ["postpay-collect"]: "Por colectar",
    ["prepay-collect"]: "Por pagar",
    ["prepay-dropoff"]: "Por pagar",
    ["postpay-dropoff"]: "Por imponer"
  };

  return (
    <div className="grid-container">
        <Tabs
          defaultActiveKey={tabSelected}
          id="orders-tab"
          onSelect={(e) => {setTabSelected(e)}}
        >
          <Tab eventKey="Todas" title="Todas">
            {tabSelected === "Todas" && (
              <GridSetterWoo
                tab="Todas"
                ecommerce={ecommerce}
              />
            )}
          </Tab>
          <Tab eventKey="Nuevas" title={`${specialTabsNames[user.typeOfMerchant]}`}>
            {tabSelected === "Nuevas" && (
              <GridSetterWoo
                tab="Nuevas"
                ecommerce={ecommerce}
              />
            )}
          </Tab>
          <Tab eventKey="Confirmadas" title="Confirmadas">
            {tabSelected === "Confirmadas" && (
              <GridSetterWoo
                tab="Confirmadas"
                ecommerce={ecommerce}
              />
            )}
          </Tab>

          <Tab eventKey="Entregadas" title="Entregadas">
            {tabSelected === "Entregadas" && (
              <GridSetterWoo
                tab="Entregadas"
                ecommerce={ecommerce}
              />
            )}
          </Tab>

          <Tab eventKey="Con problemas" title="Con problemas">
            {tabSelected === "Con problemas" && (
              <GridSetterWoo
                tab="Con problemas"
                ecommerce={ecommerce}
              />
            )}
          </Tab>
        </Tabs>
    </div>
  );
};
