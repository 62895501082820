import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export default function EditOrderWooModal({
  isOpen,
  onClose,
  onSave,
  currentPackageAmount,
}) {
  const [packageAmount, setPackageAmount] = useState(
    parseInt(currentPackageAmount)
  );
  useEffect(() => {
    setPackageAmount(parseInt(currentPackageAmount));
  }, [isOpen, currentPackageAmount]);

  const handlePacketAmountChange = (e) => {
    const newValue = parseInt(e.target.value);

    if (isNaN(newValue) || newValue < 1 || newValue > 50) {
      return;
    }

    setPackageAmount(newValue);
  };

  if (!isOpen) return null;
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Orden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formNumber">
            <Form.Label>Cantidad de bultos:</Form.Label>
            <Form.Control
              type="number"
              value={packageAmount}
              onChange={handlePacketAmountChange}
              style={{ maxWidth: '100px' }}
              min={1}
              max={50}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cerrar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onClose();
            onSave({ packageAmount: parseInt(packageAmount) });
          }}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
