import React from "react";
import { Route, Switch } from "react-router-dom";
import { Welcome } from "../../../pages/onboarding/Welcome";
import { Finish } from "../../../pages/onboarding/Finish";
import PanelRoute from "../panel/panelRoute";
import { DistributionCenter } from "../../../pages/onboarding/tiendanube-only/DistributionCenter";
import { DeliveryTimes } from "../../../pages/onboarding/tiendanube-only/DeliveryTimes";

export default function CustomerTNRoute({ rootCustomerPath }) {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`/${rootCustomerPath}/tiendanube/welcome`}
          component={Welcome}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/tiendanube/location-list`}
          component={DistributionCenter}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/tiendanube/rate`}
          component={DeliveryTimes}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/tiendanube/finish`}
          component={Finish}
        />

        <Route
          path={`/${rootCustomerPath}/tiendanube/panel`}
          render={() => <PanelRoute rootCustomerPath={rootCustomerPath} />}
        />

        <Route
          exact
          path={`/${rootCustomerPath}/tiendanube`}
          component={Welcome}
        />
      </Switch>
    </>
  );
}
