import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { Route, Switch } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  changeShopifyName,
  setEcommerceURL,
} from '../../../services/shared/utils.service';
import { useAuthStore } from '../../../store';
import { customerName } from '../../../data/InfoSource';
import { PanelMainMenu } from '../../../components/panel/PanelMainMenu';
import {
  LogoContainerShopify,
  LogoContainerTiendanube,
  LogoContainerVtex,
  LogoContainerWoo,
} from '../../../components/units/LogoContainer';
import tableCorner from '../../../assets/tableCorner.svg';
import { GridContainer } from '../../../pages/panel/GridContainer';
import { LocationsConfig } from '../../../pages/panel/LocationsConfig';
import { LocationSingleConfig } from '../../../pages/panel/LocationSingleConfig';
import { DeliveryPromiseConfig } from '../../../pages/panel/DeliveryPromiseConfig';
import { RenderMap } from '../../../pages/panel/RenderMap';
import { Settings } from '../../../pages/panel/Settings';
import { CreationFormConfig } from '../../../pages/panel/tiendanube-only/CreationFormConfig';
import { DeliveryTimesConfig } from '../../../pages/panel/tiendanube-only/DeliveryTimesConfig';
import { CreateCenterConfig } from '../../../pages/panel/vtex-only/CreateCenterConfig';
import { Account } from '../../../pages/panel/vtex-only/Account';
// import { Dashboard } from '../../../../pages/panel/vtex/Dashboard';
import { GridContainerWoo } from '../../../pages/panel/GridContainerWoo';

export default function PanelRoute({ rootCustomerPath }) {
  const location = useLocation();
  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
  const user = useAuthStore((state) => state.authData.user);
  const removeAuth = useAuthStore((state) => state.removeAuthData);

  const { shop = '' } = queryString.parse(location.search);
  const { store = '' } = queryString.parse(location.search);

  useEffect(() => {
    if (user && user?.storeId && (shop || store)) {
      if (shop && user?.storeId !== shop) {
        removeAuth();
      }
      if (store && user?.storeId !== store) {
        removeAuth();
      }
      return;
    }
    document.title = `${customerName} - ${capitalizeFirstLetter(ecommerce)}`;
  }, [user, shop, store, ecommerce, removeAuth]);

  return (
    <div className="container-fluid" id="panel-m">
      <div className="corner-background">
        <img src={tableCorner} alt="" />
      </div>
      <div className="row">
        <div className="col col-12">
          {ecommerce === 'shopify' && (
            <LogoContainerShopify ecommerce={ecommerce} />
          )}
          {ecommerce === 'tiendanube' && (
            <LogoContainerTiendanube ecommerce={ecommerce} />
          )}
          {ecommerce === 'vtex' && <LogoContainerVtex ecommerce={ecommerce} />}
          {ecommerce === 'woo' && <LogoContainerWoo ecommerce={ecommerce} />}
        </div>
      </div>
      <div className="row row-grid">
        <div className="col-2 main-nav">
          <PanelMainMenu ecommerce={ecommerce} />
        </div>
        <div className="col-10 content-wrapper">
          <Switch>
            {ecommerce !== 'woo' ? (
              <Route
                path={`/${rootCustomerPath}/${changeShopifyName(
                  ecommerce
                )}/panel/order`}
                render={() => (
                  <GridContainer
                    ecommerce={ecommerce}
                    shop={shop}
                    store={store}
                  />
                )}
              />
            ) : (
              <Route
                path={`/${rootCustomerPath}/woo/panel/order`}
                render={() => (
                  <GridContainerWoo
                    ecommerce={ecommerce}
                    shop={shop}
                    store={store}
                  />
                )}
              />
            )}

            <Route
              exact
              path={`/${rootCustomerPath}/secommerce/panel/location-list`}
            >
              <LocationsConfig />
            </Route>
            <Route
              exact
              path={`/${rootCustomerPath}/secommerce/panel/location-list/:id`}
            >
              <LocationSingleConfig />
            </Route>
            <Route exact path={`/${rootCustomerPath}/secommerce/panel/rate`}>
              <DeliveryPromiseConfig />
            </Route>
            <Route
              exact
              path={`/${rootCustomerPath}/secommerce/panel/dropoff-points`}
            >
              <RenderMap type={'dropoff'} />
            </Route>
            <Route
              exact
              path={`/${rootCustomerPath}/secommerce/panel/pickup-points`}
            >
              <RenderMap type={'pickup'} />
            </Route>
            <Route
              exact
              path={`/${rootCustomerPath}/secommerce/panel/settings`}
            >
              <Settings />
            </Route>

            <Route
              exact
              path={`/${rootCustomerPath}/tiendanube/panel/location-list`}
            >
              <CreationFormConfig />
            </Route>
            <Route exact path={`/${rootCustomerPath}/tiendanube/panel/rate`}>
              <DeliveryTimesConfig />
            </Route>
            <Route
              exact
              path={`/${rootCustomerPath}/tiendanube/panel/dropoff-points`}
            >
              <RenderMap type={'dropoff'} />
            </Route>
            <Route
              exact
              path={`/${rootCustomerPath}/tiendanube/panel/pickup-points`}
            >
              <RenderMap type={'pickup'} />
            </Route>

            {/* <Route exact path={`/${rootCustomerPath}/vtex/panel/dashboard`}> <Dashboard /></Route> */}
            <Route exact path={`/${rootCustomerPath}/vtex/panel/location-list`}>
              <LocationsConfig />
            </Route>
            <Route
              exact
              path={`/${rootCustomerPath}/vtex/panel/location-list/:id`}
            >
              <LocationSingleConfig />
            </Route>
            <Route exact path={`/${rootCustomerPath}/vtex/panel/create-center`}>
              <CreateCenterConfig />
            </Route>
            <Route
              exact
              path={`/${rootCustomerPath}/vtex/panel/dropoff-points`}
            >
              <RenderMap type={'dropoff'} />
            </Route>
            <Route exact path={`/${rootCustomerPath}/vtex/panel/pickup-points`}>
              <RenderMap type={'pickup'} />
            </Route>
            <Route exact path={`/${rootCustomerPath}/vtex/panel/account`}>
              <Account />
            </Route>

            {/* Routes WOO */}
            <Route exact path={`/${rootCustomerPath}/woo/panel/dropoff-points`}>
              <RenderMap type={'dropoff'} />
            </Route>
            <Route exact path={`/${rootCustomerPath}/woo/panel/pickup-points`}>
              <RenderMap type={'pickup'} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
