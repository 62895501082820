import React from "react";
import customerColor from "../../assets/customerColor.png";
import shopifyBlack from "../../assets/logosBlack/shopifyBlack.png";
import tiendanubeBlack from "../../assets/logosBlack/tiendanubeBlack.png";
import vtexBlack from "../../assets/logosBlack/vtexBlack.png";
import wooBlack from "../../assets/logosBlack/wooBlack.svg";

import { customerName } from "../../data/InfoSource";
import { capitalizeFirstLetter } from "../../services/shared/utils.service";

export const LogoContainerShopify = ({ ecommerce }) => {
  return (
    <div className="logo-container">
      <img src={customerColor} alt={`${customerName} logo`} />
      {/* <img src={shopifyBlack} alt="Shopify logo" className={`${ecommerce}`}/> */}
    </div>
  );
};

export const LogoContainerTiendanube = ({ ecommerce }) => {
  return (
    <div className="logo-container">
      <img src={customerColor} alt={`${customerName} logo`} />
      <img
        src={tiendanubeBlack}
        alt="Tiendanube logo"
        className={`${ecommerce}`}
      />
    </div>
  );
};

export const LogoContainerVtex = ({ ecommerce }) => {
  return (
    <div className="logo-container">
      <img src={customerColor} alt={`${customerName} logo`} />
      <img src={vtexBlack} alt="Vtex logo" className={`${ecommerce}`} />
    </div>
  );
};

export const LogoContainerWoo = ({ ecommerce }) => {
  return (
    <div className="logo-container">
      <img
        src={customerColor}
        alt={`${capitalizeFirstLetter(customerName)} logo`}
      />
      <img src={wooBlack} alt="Woo logo" className={`${ecommerce}`} />
    </div>
  );
};
