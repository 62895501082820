import React from 'react'

export const CloseButtonIcon = ({customClass, onClick}) => {
  return (
    <button
      className={customClass}
      onClick={onClick}
      type='button'
      style={{display: 'flex'}}
    >
      <svg 
        viewBox="0 0 18 18" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L17 17" stroke="#2D2D2D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1 17L17 1" stroke="#2D2D2D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      </svg>
    </button>
  )
}