import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ButtonSmall } from '../../units/Button';
import { childVariants, fadeInAndUp1Sec, popInCheck } from '../../../services/shared/animation.service';
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { useAuthStore } from '../../../store';

const OPTIONS = [
	{
		type: "full",
		text: "Integración de Órdenes, Puntos Pickit y Envío a Domicilio",
	},
	{
		type: "puntos", 
		text: "Integración solo para Puntos Pickit",
	},
];

const AnimatedCheck = () => (
	<motion.div variants={popInCheck} className='type-check_selected'>
		<BsFillCheckCircleFill  />
	</motion.div>
);

const TypeOption = ({option, currentType, handleSelect}) => (
	<button
		onClick={(e) => handleSelect(e, option.type)}
		className={currentType === option.type ? 'type_selected' : ''}
	>
		{ option.text }

		{ currentType === option.type && <AnimatedCheck /> }
	</button>
);

export const IntegrationTypeSelect = ({setTypeSelected, ecommerce}) => {
	const [currentType, setCurrentType] = useState('');
	const addAuth = useAuthStore((state) => state.addAuthData);

	const handleTypeSelected = (e) => {
			e.preventDefault();
			//proceeds to onboarding form
			setTypeSelected(true);

			//sets fullIntegration store value
			addAuth({
				user: {
					fullIntegration: currentType === 'full',
				}
			});
	}

	const handleSelect = (e, type) => {
			e.preventDefault();
			setCurrentType(type);
	}

	return (
		<motion.div className="type-select-wrapper" variants={fadeInAndUp1Sec} initial="hidden" animate="visible" >
			<motion.div variants={childVariants} className="mb-2 mb-xxl-3">
				<h2 className="small-title fw-bold">
					Selecciona el tipo de integración
				</h2>
			</motion.div>

			<motion.div variants={childVariants} className="type-buttons step-subtitle" >
				{OPTIONS.map((option) => (
					<TypeOption 
						option={option} 
						currentType={currentType} 
						handleSelect={handleSelect}
					/>
				))}
			</motion.div>

			<motion.div variants={childVariants}>
				<ButtonSmall 
					ecommerce={ecommerce}
					type="submit"
					btnTxt="Siguiente"
					extraClass={"fw-bold"}
					onClickFn={handleTypeSelected}
					isDisabled={currentType === ''}
				/>
			</motion.div>
		</motion.div>
	)
}
