import React from 'react';
import { Dropdown } from "react-bootstrap";

export const StatusDropdownFilter = ({tabSelected, statusArr, handleSelection, dataSetter, data}) => {

    const colors = {
      "Solicitud pendiente": "red",
      "Pendiente de recolección": "red",
      "Todos": "blue"
    }
    if(tabSelected==="pending"){
    return (
      <div className="px-2 border-0">
        {statusArr.map((statusItem, index) => (
          <Dropdown.Item
            key={index}
            eventKey={index}
            href={"javascript:void(0)"}
            onClick={() => handleSelection(statusItem, data, dataSetter)}
          >
            {statusItem}
          </Dropdown.Item>
        ))}

      </div>
    );
    }
}