import {
  post as restPost,
  get as restGet,
  put as restPut,
  del as restDelete,
} from '../services/shared/rest.service';

export const statusByTab = {
  pendingTab: [
    'pending',
    'restored',
    'pendingPayment',
    'paymentRejected',
    'pendingPaymentApproval',
    'waitingLabel',
    'label created',
    'labelPreprint',
    'waitingDropoff',
  ],
  createdTab: [
    'inRetailer',
    'availableForDropRetailer',
    'courier',
    'crossdock',
    'inDropOffPoint',
    'Disponible para Colecta',
    'inPikcitPoint',
    'availableForCollect',
  ],
  deliveredTab: ['delivered'],
  issueTab: [
    'returnedToSender',
    'returnToSender',
    'lost',
    'cancelled',
    'error',
  ],
};

// Initial info preparation
export const getVtexUpdatedLocations = async (storeId = '') => {
  try {
    const endpoint = `/panel/locations?shop=${storeId}`;
    const result = await restGet(endpoint);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const createVtexPanelLocation = async (params) => {
  try {
    const endpoint = `/panel/location`;
    const result = await restPost(endpoint, params);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getOrders = async (storeId = '') => {
  try {
    const endpoint = `/panel/orders?shop=${storeId}`;
    const result = await restGet(endpoint);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getDropoffPoints = async (storeId = '') => {
  try {
    const endpoint = `/panel/points/dropoff?shop=${storeId}`;
    const result = await restGet(endpoint);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getPickupPoints = async (storeId = '') => {
  try {
    const endpoint = `/panel/points/pickup?shop=${storeId}`;
    const result = await restGet(endpoint);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getPickupPointsMap = async (storeId = '', type = 'pickup') => {
  try {
    const endpoint = `/panel/points-map/${type}?shop=${storeId}`;
    const result = await restGet(endpoint);
    return result;
  } catch (error) {
    throw error.message;
  }
};

const setTab = (status) => {
  if (statusByTab.pendingTab.includes(status)) {
    return 'pending';
  }
  if (statusByTab.createdTab.includes(status)) {
    return 'created';
  }
  if (statusByTab.deliveredTab.includes(status)) {
    return 'delivered';
  }
  if (statusByTab.issueTab.includes(status)) {
    return 'issue';
  }
};

const setTabWoo = (status) => {
  if (
    status === 'pending' ||
    status === 'restored' ||
    status === 'pendingPayment' ||
    status === 'paymentRejected' ||
    status === 'pendingPaymentApproval' ||
    status === 'forRegister' ||
    status === 'labelCreated'
  ) {
    return 'Nuevas';
  }
  if (
    status === 'courier' ||
    status === 'inDropOffPoint' ||
    status === 'inPickitPoint' ||
    status === 'inRetailer' ||
    status === 'availableForCollect' ||
    status === 'availableForDrop' ||
    status === 'availableForDropRetailer' ||
    status === 'free' ||
    status === 'crossdock' ||
    status === 'pointDistribution' ||
    status === 'homeDistribution' ||
    status === 'notDelivery' ||
    status === 'point' ||
    status === 'lastMile' ||
    status === 'waiting' ||
    status === 'retired' ||
    status === 'availableForDropPoint' ||
    status === 'availableForDropOff' ||
    status === 'inRetired' ||
    status === 'deliveryLastMile' ||
    status === 'returnedToSenderRetailer' ||
    status === 'Retailer' ||
    status === 'En Retailer'
  ) {
    return 'Confirmadas';
  }
  if (status === 'delivered') {
    return 'Entregadas';
  }
  if (
    status === 'siniestrado' ||
    status === 'devueltoARemitente' ||
    status === 'cancelled' ||
    status === 'canceledOnPickit' ||
    status === 'error' ||
    status === 'returnToSender' ||
    status === 'returnedToSender' ||
    status === 'expired' ||
    status === 'lost' ||
    status === 'refundRetailer' ||
    status === 'returnedToSenderPoint' ||
    status === 'refund'
  ) {
    return 'Con problemas';
  }
};

const setTooltipMessage = (status, errorPhrase) => {
  const messagesList = {
    pending: 'Debes crear tu etiqueta para avanzar',
    delivered: 'Tu paquete fue entregado correctamente',
    inRetailer: 'Tu paquete aún se encuentra en tu local',
    availableForDropRetailer: 'Tu paquete aún se encuentra en tu local',
    courier: 'Tenemos tu paquete, pronto haremos la entrega',
    crossdock: 'Tenemos tu paquete, pronto haremos la entrega',
    inDropOffPoint:
      'Tu paquete se encuentra en el punto dropoff, pronto lo recogeremos',
    'Disponible para Colecta':
      'Tu paquete se encuentra en el punto dropoff, pronto lo recogeremos',
    returnToSender: 'Tu paquete está volviendo a tu tienda',
    returnedToSender: 'Tu paquete ha vuelto a tu tienda',
    lost: 'Tu paquete tiene un inconveniente, contactate con nosotros',
    inPikcitPoint:
      'Tu paquete se encuentra listo para retirar en el punto seleccionado',
    cancelled: 'Tu envío fue cancelado manualmente',
    error: errorPhrase || 'Ocurrió un error',
    waitingLabel:
      'Este proceso puede tardar un momento. Por favor, actualice la tabla para ver los resultados',
    'label created': 'Has creado con éxito tu etiqueta',
    labelPreprint:
      'Servicio de etiquetas pre-impresa, no se dispone de una etiqueta web',
    restored: 'Tu envío ha sido restaurado correctamente',
    pendingPayment: 'Debes pagar por tu etiqueta para continuar',
    paymentRejected: 'Tu pago fue rechazado, vuelve a intentarlo',
    pendingPaymentApproval: 'Tu pago se está procesando',
    availableForCollect: 'Listo para colectar',
    waitingDropoff: 'Debes llevar el paquete a un punto pickit',
  };
  return messagesList[status];
};

const setTranslation = (status) => {
  const translations = {
    pending: 'Pendiente',
    delivered: 'Entregado',
    inRetailer: 'En la tienda',
    availableForDropRetailer: 'En la tienda',
    courier: 'Recepcionado',
    crossdock: 'Recepcionado',
    inDropOffPoint: 'En Punto Pickit',
    'Disponible para Colecta': 'En Punto Pickit',
    returnToSender: 'En devolución',
    returnedToSender: 'Devuelto a remitente',
    lost: 'Envío con problema',
    inPikcitPoint: 'Listo para colectar',
    cancelled: 'Cancelado',
    error: 'Error',
    waitingLabel: 'Creando etiqueta',
    labelPreprint: 'Etiqueta pre-impresa',
    'label created': 'Etiqueta creada',
    restored: 'Restaurado',
    pendingPayment: 'Pago pendiente',
    paymentRejected: 'Pago rechazado',
    pendingPaymentApproval: 'Pago pendiente de aprobación',
    availableForCollect: 'Listo para colectar',
    waitingDropoff: 'Pendiente de imposición',
  };
  return translations[status];
};

const setModeTranslation = (type) => {
  if (type === 'ship') {
    return 'Domicilio';
  } else {
    return 'Punto de retiro';
  }
};

export const handleOrdersList = async (data) => {
  let formattedOrders = [];
  let key = 0;
  for (const order of data) {
    key++;
    formattedOrders.push({
      key: key,
      ecommerceNumber: order.orderNumber,
      orderId: order.orderId,
      guideNumber: order.trackingId,
      originalStatus: order.trackingStatus,
      status: setTranslation(order.trackingStatus),
      date: order.date,
      trackingUrl: order.trackingUrl,
      label: order.label,
      creationDate: order.date,
      mode: setModeTranslation(order.shippingType),
      error: order.error,
      overallTab: setTab(order.trackingStatus),
      message: setTooltipMessage(order.trackingStatus, order.error),
      isNew: order.isNew,
      preferenceId: order.preferenceId,
    });
  }
  return formattedOrders;
};

export const handleOrdersListWoo = async (data, tab) => {
  let formattedOrders = [];
  let key = 0;
  for (const order of data) {
    key++;
    formattedOrders.push({
      key: key,
      id: order.id,
      ecommerceNumber: order.orderId,
      orderId: order.orderId,
      guideNumber: order.trackingId,
      originalStatus: order.trackingStatus.trim(),
      status: order.trackingStatusText.trim(),
      date: order.date,
      trackingUrl: order.trackingUrl,
      label: order.label,
      creationDate: order.date,
      trackingColorStatusText: order.trackingColorStatusText,
      mode: order.mode,
      error: order.error,
      overallTab: setTabWoo(order.trackingStatus.trim()),
      message: order.trackingStatusDescription,
      isNew: order.isNew,
      buttons: order.buttons,
      packageAmount: order.packageAmount,
    });
  }
  return formattedOrders;
};

// single order actions
export const createOrder = async (ordersArr, storeId = '') => {
  try {
    const endpoint = `/panel/fulfill-orders?shop=${storeId}`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const deliverOrder = async (ordersArr, storeId = '') => {
  try {
    const endpoint = `/panel/request-collect?shop=${storeId}`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const payOrder = async (ordersArr, storeId = '') => {
  try {
    const endpoint = `/panel/pay?shop=${storeId}`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const cancelOrder = async (ordersArr, storeId = '') => {
  try {
    const endpoint = `/panel/cancel-orders?shop=${storeId}`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const retryOrder = async (ordersArr, storeId = '') => {
  try {
    const endpoint = `/panel/repeat-orders?shop=${storeId}`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const restoreSingleOrder = async (ordersArr, storeId = '') => {
  try {
    const endpoint = `/panel/restore-orders?shop=${storeId}`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getOrderTicket = async (ordersArr, storeId = '') => {
  try {
    const endpoint = `/panel/download-labels?shop=${storeId}`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

// client's info modal

export const getClientDetail = async (orderId, storeId = '') => {
  try {
    const endpoint = `/panel/order-info?shop=${storeId}&orderId=${orderId}`;
    const result = await restGet(endpoint);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const saveClientInfo = async (params, storeId = '') => {
  try {
    const endpoint = `/panel/order-info?shop=${storeId}`;
    const result = await restPut(endpoint, params);
    return result;
  } catch (error) {
    throw error.message;
  }
};

// End-Points WOO

export const getOrdersWoo = async (
  page = 1,
  per_page = 10,
  tab = 'Todas',
  search = null,
  start = null,
  end = null
) => {
  try {
    let endpoint =
      tab === 'Todas'
        ? `/api/orders?page=${page}&per_page=${per_page}`
        : `/api/orders?page=${page}&per_page=${per_page}&status=${tab}`;

    if (search) {
      endpoint = endpoint + `&search=${search}`;
    }
    if (start && end) {
      endpoint = endpoint + `&from=${start}&to=${end}`;
    }

    const result = await restGet(endpoint);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getOrderTicketWoo = async (ordersArr) => {
  try {
    const baseUrl = process.env.REACT_APP_BASE_URL.replace(
      'ecommerce',
      'woocommerce'
    ).replace('/api/v1', '');
    var endpoint = `/labels?`;
    ordersArr.forEach((element) => {
      endpoint += `ids[]=${element}&`;
    });
    window.open(`${baseUrl}${endpoint}`, '_blank');
  } catch (error) {
    throw error.message;
  }
};

export const cancelOrderWoo = async (ordersArr) => {
  try {
    const endpoint = `/api/orders/${ordersArr}`;
    const result = await restDelete(endpoint);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const deliverOrderWoo = async (ordersArr) => {
  try {
    const endpoint = `/api/orders/pickit-ready-to-ship`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const createOrderWoo = async (ordersArr) => {
  try {
    const endpoint = `/api/orders/pickit-register`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const restoreSingleOrderWoo = async (ordersArr) => {
  try {
    const endpoint = `/api/orders/${ordersArr}/restore`;
    const result = await restPost(endpoint);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getDropoffPointsWoo = async () => {
  try {
    const endpoint = `/api/points?small=true`;
    const result = await restGet(endpoint);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const payOrderWoo = async (ordersArr) => {
  try {
    const endpoint = `/api/mercadopago/payment`;
    const result = await restPost(endpoint, ordersArr);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const setOrdersSetting = async (body, storeId = '') => {
  try {
    const endpoint = `/panel/configuration?shop=${storeId}`;
    const result = await restPost(endpoint, body);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getOrdersSetting = async (storeId = '') => {
  try {
    const endpoint = `/panel/configuration?shop=${storeId}`;
    const result = await restGet(endpoint);

    return result;
  } catch (error) {
    throw error.message;
  }
};
