import React from 'react';
import { LogoContainerShopify, LogoContainerTiendanube, LogoContainerVtex } from '../units/LogoContainer';
import introIllustration from '../../assets/introIllustration.png';
import { capitalizeFirstLetter } from '../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { customerName } from '../../data/InfoSource';
import { childVariants, fadeInAndRight } from '../../services/shared/animation.service';
import { useState } from 'react';
import { ModalCountryFlags } from './ModalCountryFlags';

export const IntroColumn = ({ ecommerce, storeName = "", isLoading }) => {
  const [showCountryModal, setShowCountryModal] = useState(false);

  return (
    <motion.div className={`col col-4 welcome-grid ${ecommerce}`} variants={fadeInAndRight} initial="hidden" animate="visible">
      {ecommerce === "shopify" && <LogoContainerShopify ecommerce={ecommerce} />}
      {ecommerce === "tiendanube" && <LogoContainerTiendanube ecommerce={ecommerce} />}
      {ecommerce === "vtex" && <LogoContainerVtex ecommerce={ecommerce} />}
      <div className="welcome-text">
        <div>
          <motion.h1 variants={childVariants} className={`main-title ${ecommerce}`}>
            {!isLoading && !storeName ? `¡Hola!` : `¡Hola ${storeName}!`}
          </motion.h1>
          <motion.p variants={childVariants} className="subtitle">
            Gracias por comenzar a usar la aplicación de <span className="fw-bold">{customerName}</span> para <span className="fw-bold">{capitalizeFirstLetter(ecommerce)}</span>. Para continuar con el proceso de configuración, es necesario que ya cuentes con una cuenta en <span className="fw-bold">{customerName}</span>.
            Si aún no la tienes, <button type='button' className={`btn-link fw-bold p-0`} onClick={() => setShowCountryModal(true)}>crea tu cuenta aquí</button>.
          </motion.p>
        </div>
      </div>
      <div className="illustration-wrapper">
        <img src={introIllustration} alt="" />
      </div>
      {(showCountryModal && ecommerce) &&
        <ModalCountryFlags ecommerce={ecommerce} isContact={false} handleClose={() => setShowCountryModal(false)}/>
      }
    </motion.div>
  )
}
