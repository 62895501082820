import React from 'react'

export const InfoIcon = ({customClass, strokeColor, bgColor, onClick}) => {
  return (
    <svg 
      className={customClass ? customClass : ''} 
      width="69" 
      height="68" 
      viewBox="0 0 69 68" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle 
        cx="34.5" 
        cy="34" r="32" 
        fill={bgColor ? bgColor : "#FFE8E8"}
      />
      <path 
        d="M50.5 6.28103C45.7932 3.55831 40.3286 2 34.5 2C16.8269 2 2.5 16.3269 2.5 34C2.5 51.6731 16.8269 66 34.5 66C52.1731 66 66.5 51.6731 66.5 34C66.5 30.3221 65.8795 26.7891 64.7375 23.5" 
        stroke={strokeColor ? strokeColor : "#FF6C0E"} 
        strokeWidth="3.5" 
        strokeLinecap="round" 
      />
      <path 
        d="M35 45.5V32" 
        stroke={strokeColor ? strokeColor : "#FF6C0E"} 
        strokeWidth="3.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M35 25H35.0338" 
        stroke={strokeColor ? strokeColor : "#FF6C0E"} 
        strokeWidth="3.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  )
}