import React from 'react';
import { OnboardingHeader } from '../../../components/onboarding/OnboardingHeader';
import { CreationForm } from '../../../components/onboarding/tiendanube-only/CreationForm';
import { useAuthStore } from '../../../store';
import { capitalizeFirstLetter } from '../../../services/shared/utils.service';
import {motion} from 'framer-motion';
import { customerName } from '../../../data/InfoSource';
import { fadeInAndRight, fadeInAndUp } from '../../../services/shared/animation.service';

export const DistributionCenter = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);

    return (
        <div className="container-fluid" id="distributionCenter">
            <div className="row">
                <div className="col-4 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                    <OnboardingHeader step={1} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-8 content-wrapper">
                    <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
                    <h1 className="step-title fw-bold">Tienda</h1>
                    <h2 className="step-subtitle">Crea tu punto de despacho para que podamos retirar tus paquetes <span className="fw-bold">{capitalizeFirstLetter(customerName)}</span>.</h2>
                    </motion.div>
                    
                    <CreationForm ecommerce={ecommerce} />
                </div>
            </div>
        </div>
    )
}
