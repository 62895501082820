import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { createLocation } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { validateInputs } from '../../../services/shared/utils.service';
import { useAuthStore, useonboardingStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { InputsTextarea, InputsWithTooltip } from '../../units/Inputs';
import chevronRw from '../../../assets/chevronRw.png';
import { motion } from 'framer-motion';
import { childVariants, fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../../services/shared/animation.service';

import { customerRouteName } from '../../../data/InfoSource';
import { createVtexPanelLocation } from '../../../services/panel.service';

export const CreateNewCenter = () => {
  const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
  let history = useHistory();
  const user = useAuthStore((state) => state.authData.user);
  const addData = useonboardingStore((state) => state.addData);
  const store = useonboardingStore((state) => state.onboardingData.store);

  const [currentlocations, setCurrentlocations] = useState(store?.currentlocations);

  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({})
  const [number, setNumber] = useState("");
  const [body, setBody] = useState({
    name:"",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    city: "",
    province: "",
    zip: "",
    street: "",
    notes: "",

    country: "",
    country_code: user.country || "",
    address2: "-",
  });


  const handleChange = (e) => {
    if(e.target.id==="number"){
      setNumber(e.target.value)
    } else {
      setBody({...body, [e.target.id]: e.target.value });
    }
  };


  const handleCreationNewCenter = async (e) => {
    e.preventDefault();
    if (validateInputs(body, setFormErrors)) {
      setIsLoading(true);
      let obj = { location: { ...body, number } };
      try {
        let result;
        if(user.insidePanel){
          result = await createVtexPanelLocation(obj);
        } else {
          result = await createLocation(obj);
        }
        if (result && result.success) {
          let updatedLocations = { ...currentlocations, locations: [ ...currentlocations.locations, result.location] };
          addData({
            store: {
              currentlocations: updatedLocations,
            },
          });
          showSuccess(result.message);
          if (user.insidePanel) {
            history.push(
              `/${customerRouteName}/${ecommerce}/panel/location-list`
            );
          } else {
            history.push(
              `/${customerRouteName}/${ecommerce}/location-list`
            );
          }
        }
        if (result?.error) {
          showError(result.error);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showError(error);
      }
    } else {
      return
    }
  };

  return (
    <form className="col" onSubmit={handleCreationNewCenter}>
      <motion.div className="form-grid-two-columns" variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
        <div className="d-flex flex-column justify-content-between">
            <motion.div variants={childVariants} className="mb-1 w-100">
                <InputsWithTooltip
                    value={body.name}
                    id="name"
                    type="text"
                    labelTxt="Nombre del nuevo muelle*"
                    onChangeFn={handleChange}
                    resultValidation={formErrors?.name ? "is-invalid" : ""}
                />
                {formErrors.name && <span className="invalid-msg">{formErrors.name}</span>}
            </motion.div>
          
          {/* <div className="form-grid-two-columns"> */}
            <motion.div variants={childVariants} className="mb-1 w-100">
                <InputsWithTooltip
                    value={body.firstName}
                    id="firstName"
                    type="text"
                    labelTxt="Nombre de quien prepara los pedidos*"
                    onChangeFn={handleChange}
                    resultValidation={formErrors?.firstName ? "is-invalid" : ""}
                />
                {formErrors.firstName && <span className="invalid-msg">{formErrors.firstName}</span>}
            </motion.div>

            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                  value={body.lastName}
                  id="lastName"
                  type="text"
                  labelTxt="Apellido de quien prepara los pedidos*"
                  onChangeFn={handleChange}
                  resultValidation={formErrors?.lastName ? "is-invalid" : ""}
              />
              {formErrors.lastName && <span className="invalid-msg">{formErrors.lastName}</span>}
            </motion.div>
          {/* </div> */}

          <motion.div variants={childVariants} className="mb-1 w-100">
            <InputsWithTooltip
              value={body.phoneNumber}
              id="phoneNumber"
              type="text"
              labelTxt="Teléfono*"
              onChangeFn={handleChange}
              resultValidation = {formErrors?.phoneNumber ? "is-invalid" : ""}
            />
            {formErrors.phoneNumber && <span className="invalid-msg">{formErrors.phoneNumber}</span> }
          </motion.div>

          <motion.div variants={childVariants} className="mb-1 w-100">
            <InputsWithTooltip
                value={body.email}
                id="email"
                type="text"
                labelTxt="Correo electrónico*"
                placeholder="Ej: email@example.com"
                onChangeFn={handleChange}
                resultValidation={formErrors?.email ? "is-invalid" : ""}
            />
            {formErrors.email && <span className="invalid-msg">{formErrors.email}</span>}
          </motion.div>
        </div>
        <div className="d-flex flex-column justify-content-between">
          <motion.div variants={childVariants} className="mb-1 w-100">
            <InputsWithTooltip
                value={body.city}
                id="city"
                type="text"
                labelTxt="Ciudad*"
                onChangeFn={handleChange}
                resultValidation={formErrors?.city ? "is-invalid" : ""}
            />
            {formErrors.city && <span className="invalid-msg">{formErrors.city}</span>}
          </motion.div>

          <motion.div variants={childVariants} className="mb-1 w-100">
            <InputsWithTooltip
                value={body.province}
                id="province"
                type="text"
                labelTxt={user.country === "MX" ? "Estado*" : "Provincia*"}
                onChangeFn={handleChange}
                resultValidation={formErrors?.province ? "is-invalid" : ""}
            />
            {formErrors.province && <span className="invalid-msg">{formErrors.province}</span>}
          </motion.div>

          <motion.div variants={childVariants} className="mb-1 w-100">
            <InputsWithTooltip
                value={body.zip}
                id="zip"
                type="text"
                labelTxt="Código postal*"
                onChangeFn={handleChange}
                resultValidation={formErrors?.zip ? "is-invalid" : ""}
            />
            {formErrors.zip && <span className="invalid-msg">{formErrors.zip}</span>}
          </motion.div>

          <div className="double-input-col">
            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                  value={body.street}
                  id="street"
                  type="text"
                  labelTxt="Calle*"
                  onChangeFn={handleChange}
                  resultValidation={formErrors?.street ? "is-invalid" : ""}
              />
              {formErrors.street && <span className="invalid-msg">{formErrors.street}</span>}
            </motion.div>

            <motion.div variants={childVariants} className="mb-1 w-100">
              <InputsWithTooltip
                  value={body.number}
                  id="number"
                  type="text"
                  labelTxt="Número exterior*"
                  onChangeFn={handleChange}
                  resultValidation={formErrors?.number ? "is-invalid" : ""}
              />
              {formErrors.number && <span className="invalid-msg">{formErrors.number}</span>}
            </motion.div>
          </div>
          <motion.div variants={childVariants} className="mx-1 w-100">
            <InputsTextarea
            value={body.notes}
            id="notes"
            // placeholder
            labelTxt="Notas adicionales*"
            onChangeFn={handleChange}
            rows={2}
            maxLength={900}
            /> 
            {formErrors.notes && <span className="invalid-msg">{formErrors.notes}</span> }
          </motion.div>
        </div>
      </motion.div>

      <div className="bottomPage doubleBtn">
        <motion.div variants={growBtn} initial="hidden" animate="visible">
          <Link to={`/${customerRouteName}/${ecommerce}${user.insidePanel ? '/panel' : ""}/location-list`} className="step-subtitle fw-bold btn btn-link">Atrás</Link>
        </motion.div>

        <motion.div variants={growBtn} initial="hidden" animate="visible">
          <ButtonSmall
          ecommerce={ecommerce}
          type="submit"
          btnTxt="Crear"
          showSpinner={isLoading}
          isDisabled={(body.name && body.firstName && body.lastName && body.phoneNumber && body.email && body.city && body.province && body.zip && body.street && body.notes && number) ? false : true}
          />
        </motion.div>
        </div>
    </form>
  );
};