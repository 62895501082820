//hooks
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//utils
import { del as restDelete } from "../../services/shared/rest.service";
import { showError, showSuccess } from "../../services/shared/alert.service";
import { changeShopifyName } from "../../services/shared/utils.service";
import { customerRouteName } from "../../data/InfoSource";

//components
import { ButtonSmall } from "../units/Button";
import { CloseButtonIcon } from "../units/CloseButtonIcon";
import { InfoIcon } from "../units/InfoIcon";

export const ModalConfirmDeleteAccount = ({ ecommerce, handleClose }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const result = await restDelete("/account");
      if (result && result.success) {
        showSuccess(result.message);
      }
      setTimeout(() => {
        setIsLoading(false);
        history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/`);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      showError(error);
    }
  };

  return (
    <div className="white-backdrop-fixed">
      <div className="confirmDelete-content-container">
        <CloseButtonIcon onClick={handleClose} customClass={"closeButton"} />

        <InfoIcon />

        <h2 className="subtitle">¿Estás seguro de eliminar la cuenta?</h2>
        <p>Recuerda que esta acción es irreversible</p>

        <div className="doubleBtn confirmDelete-btns">
          <ButtonSmall
            btnTxt={"Cancelar"}
            onClickFn={handleClose}
            type={"button"}
            extraClass={"confirmCancel-btn"}
          />
          <ButtonSmall
            btnTxt={"Eliminar"}
            onClickFn={handleDelete}
            type={"button"}
            extraClass={"confirmDelete-btn"}
            showSpinner={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
