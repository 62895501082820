import axios from "axios";
import dotenv from "dotenv";

dotenv.config();
export function getBaseUrl() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (typeof window !== "undefined") {
    const currentUrl = new URL(window.location.href);
    const ecommerce = currentUrl.pathname.split("/")[2];
    if (ecommerce === "vtex" || ecommerce === "tiendanube") {
      return baseUrl.replace("ecommerce", ecommerce);
    } else if (ecommerce === "woo" || ecommerce === "woocommerce") {
      return baseUrl.replace("ecommerce", "woocommerce").replace("/api/v1", "");
    }
  }
  return baseUrl;
}

export const post = async (endpoint, body, headers) => {
  try {
    const url = `${getBaseUrl()}${endpoint}`;

    const response = await axios.post(url, body, {
      headers: headers ? headers : { "content-type": "application/json" },
    });

    const result = await handleResponse(response);
    return result;
  } catch (error) {
    const message = handleMessage(error);
    throw new Error(message);
  }
};

export const get = async (endpoint, headers) => {
  try {
    const url = `${getBaseUrl()}${endpoint}`;

    const response = await axios.get(url, {
      headers: headers ? headers : { "content-type": "application/json" },
    });

    const result = await handleResponse(response);
    return result;
  } catch (error) {
    const message = handleMessage(error);
    throw new Error(message);
  }
};

export const put = async (endpoint, body, headers) => {
  try {
    const url = `${getBaseUrl()}${endpoint}`;

    const response = await axios.put(url, body, {
      headers: headers ? headers : { "content-type": "application/json" },
    });

    const result = await handleResponse(response);
    return result;
  } catch (error) {
    const message = handleMessage(error);
    throw new Error(message);
  }
};

export const patch = async (endpoint, body, headers) => {
  try {
    const url = `${getBaseUrl()}${endpoint}`;

    const response = await axios.patch(url, body, {
      headers: headers ? headers : { "content-type": "application/json" },
    });

    const result = await handleResponse(response);
    return result;
  } catch (error) {
    const message = handleMessage(error);
    throw new Error(message);
  }
}

export const del = async (endpoint, headers) => {
  try {
    const url = `${getBaseUrl()}${endpoint}`;

    const response = await axios.delete(url, {
      headers: headers ? headers : { "content-type": "application/json" },
    });

    const result = await handleResponse(response);
    return result;
  } catch (error) {
    const message = handleMessage(error);
    throw new Error(message);
  }
};

const handleResponse = async (response) => {
  const { data } = response;
  if (response.status < 400) {
    return data;
  } else {
    const message = handleMessage(data);
    throw new Error(message);
  }
};

const handleMessage = (result) => {
  let msg = "Ocurrió un error en la conexión";
  if (result?.error) {
    msg = result.error;
  }
  if (result?.message) {
    msg = result.message;
  }
  if (result?.response?.data?.error) {
    msg = result.response.data.error;
  }
  if (result?.response?.data?.message) {
    msg = result.response.data.message;
  }
  return msg;
};
