import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useAuthStore, useonboardingStore } from '../../store';
import { LocationForm } from '../../components/onboarding/LocationForm';
import chevronRw from '../../assets/chevronRw.png';
import outOfZoneIllustration from '../../assets/outOfZoneIllustration.png';
import { capitalizeFirstLetter, changeShopifyName } from '../../services/shared/utils.service';
import { customerRouteName } from '../../data/InfoSource';
import {motion} from 'framer-motion';
import { fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../services/shared/animation.service';

export const LocationSingleConfig = () => {
    const location = useLocation();
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const user = useAuthStore((state) => state.authData.user);
    const insidePanel = useAuthStore((state) => state.authData.user.insidePanel);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const [singleLocation, setSingleLocation] = useState({});
    const [singleLocationIndex, setSingleLocationIndex] = useState(null);
    const [allLocations, setAllLocations] = useState(store.currentlocations);


    useEffect(() => {
        let pathArray = location.pathname.split("/");
        let selectedId = pathArray[pathArray.length-1];
        let list = store.currentlocations.locations;
        let singleLocSelected = list.find( loc => loc.locationId===selectedId );
        let singleLocIndex = list.findIndex( loc => loc.locationId===selectedId );
        setSingleLocation(singleLocSelected);
        setSingleLocationIndex(singleLocIndex);
    }, [])

    return (
            <div id="locationSingleConfig" className='content-wrapper'>
                <motion.div variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                    <h1 className="step-title fw-bold">{singleLocation.name ? singleLocation.name : `Tienda ${singleLocationIndex +1}`}</h1>
                    <h2 className="step-subtitle">{((singleLocation.address && singleLocation.city) && (!singleLocation.address.includes("null") && !singleLocation.city.includes("null"))) ? `${singleLocation.address}, ${singleLocation.city}` : "- Dirección incompleta -"}</h2>
                </motion.div>

                {singleLocation.inCoverage 
                ? <LocationForm ecommerce={ecommerce} singleLocationIndex={singleLocationIndex} allLocations={allLocations} insidePanel {...singleLocation}/>
                :
                    <>
                        <motion.div variants={fadeInAndUp} initial="hidden" animate="visible" className="OutOfZone-wrapper">
                            <div className="illustration-wrapper aboveMsg-illustration">
                                <img src={outOfZoneIllustration} alt="" />
                            </div>
                            <div className="next-steps">
                                <p className="step-subtitle outOfZone">La tienda se encuentra <span className="fw-bold">fuera de la zona de entrega</span>.</p>
                                <p className="step-subtitle">Si hay algún error en la configuración de ésta, puedes corregirlo desde <span className="fw-bold">"Configuraciones" &gt; "Sucursales"</span></p>
                            </div>
                        </motion.div>
                        <motion.div variants={growBtn} initial="hidden" animate="visible" className="bottomPage doubleBtn mt-5">
                            <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list`} className="step-subtitle fw-bold btn btn-link">Atrás</Link>
                        </motion.div>
                    </>
                }
            </div>
    )
}
