import React from "react";
import { Route, Switch } from "react-router-dom";
import { Finish } from "../../../pages/onboarding/Finish";
import { LocationList } from "../../../pages/onboarding/LocationList";
import { LocationSingle } from "../../../pages/onboarding/LocationSingle";
import { CreateCenter } from "../../../pages/onboarding/vtex-only/CreateCenter";
import { SignIn } from "../../../pages/onboarding/vtex-only/SignIn";
import { VtexKeysConfig } from "../../../pages/onboarding/vtex-only/VtexKeysConfig";
import { Welcome } from "../../../pages/onboarding/Welcome";
import PanelRoute from "../panel/panelRoute";

export default function CustomerVTEXRoute({ rootCustomerPath }) {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`/${rootCustomerPath}/vtex/sign-in`}
          component={SignIn}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/vtex/vconfig`}
          component={VtexKeysConfig}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/vtex/welcome`}
          component={Welcome}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/vtex/location-list`}
          component={LocationList}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/vtex/location-list/:id`}
          component={LocationSingle}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/vtex/create-center`}
          component={CreateCenter}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/vtex/finish`}
          component={Finish}
        />

        <Route
          path={`/${rootCustomerPath}/vtex/panel`}
          render={() => <PanelRoute rootCustomerPath={rootCustomerPath} />}
        />

        <Route exact path={`/${rootCustomerPath}/vtex`} component={SignIn} />
      </Switch>
    </>
  );
}
