import React, { useState, useEffect } from "react";
import { DeliveryPromiseForm } from "../../components/onboarding/DeliveryPromiseForm";
import { getLocations } from "../../services/onboarding.service";
import { showError } from "../../services/shared/alert.service";
import { useAuthStore, useonboardingStore } from "../../store";

export const DeliveryPromiseConfig = () => {
  console.log(useAuthStore((state) => state.authData?.user?.ecommerce));
  const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
  const user = useAuthStore((state) => state.authData.user);
  const addData = useonboardingStore((state) => state.addData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    try {
      setIsLoading(true);
      const records = await getLocations(user.storeId);
      if (records) {
        addData({
          store: {
            currentlocations: records,
          },
        });
      }
      setIsLoading(false);
    } catch (error) {
      showError(error);
    }
  }, []);

  return (
    <>
      {!isLoading && (
        <div id="deliveryConfig">
          <DeliveryPromiseForm ecommerce={ecommerce} />
        </div>
      )}
    </>
  );
};
