import React, { useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { useAuthStore } from '../../store';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { motion } from 'framer-motion';
import {
  columns,
  filterByStatus,
  filterData,
  getPreferenceId,
  handleDateChange,
  noSelection,
  onRowSelect,
  onSelectAll,
  options,
  printTickets,
  setClientInfo,
  setSingleOrderActionWoo,
  statusArgumentArray,
  handleDateChangeWoo,
  setMassiveOrdersActionsWoo,
} from '../../services/grid-woo.service';
import Skeleton from 'react-loading-skeleton';
import { ButtonSmall } from '../units/Button';
import refresh from '../../assets/refresh.svg';
import searchIcon from '../../assets/search.svg';
import { FiEdit, FiPrinter, FiX, FiRotateCw } from 'react-icons/fi';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { ModalEdit } from './ModalEdit';
import { setSingleOrderAction } from '../../services/grid.service';
import {
  childVariants,
  fadeInAndUpNoDelay,
} from '../../services/shared/animation.service';
import { StatusDropdownFilter } from './StatusDropdownFilter';
import EditOrderWooModal from './EditOrderWooModal';
import { patch } from '../../services/shared/rest.service';
registerLocale('es', es);

export const TableWoo = ({
  data,
  tabSelected,
  updateTable,
  ecommerce,
  handleTableChange,
  page,
  perPage,
  sizePage,
  afterSearch,
  filterDate,
  startDateWoo,
  endDateWoo,
  clearDatesWoo,
}) => {
  const newStarDate = startDateWoo ? new Date(startDateWoo) : null;
  const newEndDate = endDateWoo ? new Date(endDateWoo) : null;

  const auth = useAuthStore((state) => state.authData);
  const [filteredByDateList, setFilteredByDateList] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [ordersForFulfillment, setOrdersForFulfillment] = useState([]);
  const [ordersForPayment, setOrdersForPayment] = useState([]);
  const [ordersForCollect, setOrdersForCollect] = useState([]);
  const [ordersPrint, setOrdersPrint] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsModalProps, setDetailsModalProps] = useState({});
  const [showEmptyMsg, setshowEmptyMsg] = useState(false);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState(newStarDate);
  const [endDate, setEndDate] = useState(newEndDate);
  // Modal States
  const [isEditOrderModalOpen, setIsEditOrderModalOpen] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [currentPackageAmount, setCurrentPackageAmount] = useState(1);

  const patchOrder = async (body) => {
    try {
      const result = await patch(`/api/orders/${currentOrderId}`, body);
      if (result) {
        refreshTable();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const refreshTable = () => {
    updateTable(auth?.user?.storeId, tabSelected);
    setOrdersForCollect([]);
    setOrdersForPayment([]);
    setOrdersForFulfillment([]);
  };

  useEffect(() => {
    if (data.length === 0) {
      setshowEmptyMsg(true);
    } else {
      setshowEmptyMsg(false);
      setFilteredByDateList(data);
    }
  }, [data, tabSelected]);

  // --- row selection ---
  const selectRowModes = {
    mode: 'checkbox',
    clickToSelect: false,
    onSelect: (row, isSelected) =>
      onRowSelect(
        row,
        isSelected,
        ordersSelected,
        setOrdersSelected,
        ordersForFulfillment,
        setOrdersForFulfillment,
        ordersForPayment,
        setOrdersForPayment,
        ordersForCollect,
        setOrdersForCollect,
        ordersPrint,
        setOrdersPrint,
        auth.user.typeOfMerchant
      ),
    onSelectAll: (isSelected, rows) =>
      onSelectAll(
        isSelected,
        rows,
        setOrdersSelected,
        setOrdersForFulfillment,
        setOrdersForPayment,
        setOrdersForCollect,
        setOrdersPrint,
        auth.user.typeOfMerchant
      ),
    bgColor: () => {
      return `#f6f6f6`;
    },
  };

  const resetOrdersSelections = () => {
    setOrdersSelected([]);
    setOrdersForFulfillment([]);
    setOrdersForPayment([]);
    setOrdersForCollect([]);
    setOrdersPrint([]);
  };

  // -- column formatters --
  const actionsFormatter = (cell, row) => {
    let showEditar = null;
    let showLabel = null;
    let showCancel = null;
    let showRetry = null;

    if (row.originalStatus === 'forRegister') {
      showEditar = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 250 }}
          overlay={<Tooltip>Editar</Tooltip>}
        >
          <button
            type="button"
            className="btn"
            onClick={() => {
              setCurrentPackageAmount(row.packageAmount);
              setIsEditOrderModalOpen(true);
              setCurrentOrderId(row.id);
            }}
          >
            <FiEdit />
          </button>
        </OverlayTrigger>
      );
    }

    if (row.buttons.showLabel) {
      showLabel = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 250 }}
          overlay={<Tooltip>Imprimir etiqueta</Tooltip>}
        >
          <button
            type="button"
            className="btn position-relative"
            onClick={() => {
              printTickets(row.id, setIsLoading, auth?.user?.storeId);
            }}
          >
            <FiPrinter />
            {row.isNew && (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                NEW
                <span className="visually-hidden">new tag</span>
              </span>
            )}
          </button>
        </OverlayTrigger>
      );
    }

    if (row.buttons.showPickitCancel) {
      showCancel = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 250 }}
          overlay={<Tooltip>Cancelar envío</Tooltip>}
        >
          <button
            type="button"
            className="btn"
            onClick={() =>
              setSingleOrderActionWoo(
                row.id,
                auth?.user?.storeId,
                setIsLoading,
                updateTable,
                tabSelected,
                'cancel'
              )
            }
          >
            <FiX />
          </button>
        </OverlayTrigger>
      );
    }

    if (row.buttons.showRetry) {
      showRetry = (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 250 }}
          overlay={<Tooltip>Volver a realizar</Tooltip>}
        >
          <button
            type="button"
            className="btn"
            onClick={() =>
              setSingleOrderAction(
                row.orderId,
                auth?.user?.storeId,
                setIsLoading,
                updateTable,
                tabSelected,
                'retry'
              )
            }
          >
            <FiRotateCw />
          </button>
        </OverlayTrigger>
      );
    }

    return (
      <div className="actions-icons">
        {showEditar ? showEditar : <></>}
        {showLabel ? showLabel : <></>}
        {showCancel ? showCancel : <></>}
        {showRetry ? showRetry : <></>}
      </div>
    );
  };

  const singleActionsFormatter = (cell, row, index) => {
    if (row.buttons.showPayment) {
      return (
        <button
          className="badge panel-format pay-deliver"
          onClick={() =>
            getPreferenceId(row.id, auth?.user?.storeId, resetOrdersSelections)
          }
        >
          Pagar etiqueta
        </button>
      );
    }
    if (row.buttons.showRegister) {
      return (
        <button
          className="badge panel-format fulfill-deliver"
          onClick={() =>
            setSingleOrderActionWoo(
              row.id,
              auth?.user?.storeId,
              setIsLoading,
              updateTable,
              tabSelected,
              'fulfill'
            )
          }
        >
          Crear etiqueta
        </button>
      );
    }
    if (row.buttons.showReadyToShip) {
      return (
        <button
          className="badge panel-format fulfill-deliver"
          onClick={() =>
            setSingleOrderActionWoo(
              row.id,
              auth?.user?.storeId,
              setIsLoading,
              updateTable,
              tabSelected,
              'collect'
            )
          }
        >
          Solicitar colecta
        </button>
      );
    }
    if (row.buttons.showRestore) {
      return (
        <button
          className="badge panel-format restore-deliver"
          onClick={() =>
            setSingleOrderActionWoo(
              row.id,
              auth?.user?.storeId,
              setIsLoading,
              updateTable,
              tabSelected,
              'restore'
            )
          }
        >
          Restaurar
        </button>
      );
    }
  };

  // -- status filter --
  const handleSelection = (statusChoice) => {
    if (statusChoice === 'Todos') {
      setFilteredByDateList(data);
    } else {
      setFilteredByDateList(filterByStatus(data, statusChoice));
    }
  };

  const statusFilterFormatter = (column, colIndex) => {
    if (
      auth.user.typeOfMerchant === 'prepay-dropoff' &&
      tabSelected === 'Nueva'
    ) {
      return (
        <DropdownButton
          id="dropdown-basic-button"
          title={column.text}
          as={ButtonGroup}
        >
          <StatusDropdownFilter
            tabSelected={tabSelected}
            statusArr={statusArgumentArray}
            handleSelection={handleSelection}
          />
        </DropdownButton>
      );
    } else {
      return column.text;
    }
  };

  const optionsNew = options(page, perPage, sizePage);

  // --- table ---
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="id"
        data={filteredByDateList}
        columns={columns(
          ecommerce,
          actionsFormatter,
          singleActionsFormatter,
          statusFilterFormatter
        )}
        search
      >
        {(props) => (
          <motion.div
            id="fulltable"
            className="container-fluid"
            variants={fadeInAndUpNoDelay}
            initial="hidden"
            animate="visible"
          >
            {showEmptyMsg && (
              <div className="empty-msg">
                <p className="finish-small-title">
                  Aún no hay órdenes para mostrar
                </p>
              </div>
            )}
            <div className="d-flex flex-column justify-content-start h-100">
              <div className="topBar-container">
                <div className="search-container search-container-woo">
                  <input
                    className="custom-search-field"
                    placeholder="Busca por #orden o #número de seguimiento"
                    onKeyPress={afterSearch}
                  />
                  <img className="img-search" src={searchIcon} alt="search" />
                </div>
                <DatePicker
                  locale="es"
                  selected={startDate}
                  onChange={(dates) =>
                    handleDateChangeWoo(
                      dates,
                      setStartDate,
                      setEndDate,
                      filterDate
                    )
                  }
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yy"
                  placeholderText="Filtrar por fecha"
                  className="custom-range-input"
                />
                {startDate !== null && (
                  <button className="close-btn" onClick={clearDatesWoo}>
                    X
                  </button>
                )}
                {ordersSelected.length > 1 &&
                  (tabSelected !== 'Todas' ||
                    tabSelected !== 'Con problemas') && (
                    <div className="massive-btn-wrapper">
                      {tabSelected === 'Nuevas' &&
                        ordersForFulfillment.length > 0 &&
                        (auth.user.typeOfMerchant === 'postpay-collect' ||
                          auth.user.typeOfMerchant === 'postpay-dropoff') && (
                          <ButtonSmall
                            type="button"
                            btnTxt={`Crear etiqueta (${ordersForFulfillment.length})`}
                            showSpinner={false}
                            isDisabled={false}
                            onClickFn={() =>
                              setMassiveOrdersActionsWoo(
                                ordersForFulfillment,
                                auth?.user?.storeId,
                                tabSelected,
                                setIsLoading,
                                updateTable,
                                resetOrdersSelections,
                                'fulfill'
                              )
                            }
                            extraClass="grid-fixed-size-woo grid-fixed-size full"
                          />
                        )}
                      {(auth.user.typeOfMerchant === 'postpay-collect' ||
                        auth.user.typeOfMerchant === 'prepay-collect') &&
                        tabSelected === 'Nuevas' &&
                        ordersForCollect.length > 0 && (
                          <ButtonSmall
                            type="button"
                            btnTxt={`Solicitar colecta (${ordersForCollect.length})`}
                            showSpinner={false}
                            isDisabled={false}
                            onClickFn={() =>
                              setMassiveOrdersActionsWoo(
                                ordersForCollect,
                                auth?.user?.storeId,
                                tabSelected,
                                setIsLoading,
                                updateTable,
                                resetOrdersSelections,
                                'collect'
                              )
                            }
                            extraClass="grid-fixed-size-woo grid-fixed-size full"
                          />
                        )}
                      {(auth.user.typeOfMerchant === 'prepay-collect' ||
                        auth.user.typeOfMerchant === 'prepay-dropoff') &&
                        tabSelected === 'Nuevas' &&
                        ordersForPayment.length > 0 && (
                          <ButtonSmall
                            type="button"
                            btnTxt={`Pagar (${ordersForPayment.length})`}
                            showSpinner={false}
                            isDisabled={false}
                            onClickFn={() =>
                              getPreferenceId(
                                ordersForPayment,
                                auth?.user?.storeId,
                                resetOrdersSelections
                              )
                            }
                            extraClass="grid-fixed-size-woo grid-fixed-size full"
                          />
                        )}
                      {ordersPrint.length > 0 && (
                        <ButtonSmall
                          type="button"
                          btnTxt={`Imprimir (${ordersPrint.length})`}
                          showSpinner={false}
                          isDisabled={false}
                          onClickFn={() => {
                            printTickets(
                              ordersPrint,
                              setIsLoading,
                              auth?.user?.storeId,
                              resetOrdersSelections
                            );
                            setOrdersSelected([]);
                            setOrdersPrint([]);
                          }}
                          extraClass="grid-fixed-size-woo grid-fixed-size outlined"
                        />
                      )}
                    </div>
                  )}
                <div className="update-btn-container">
                  <button onClick={refreshTable}>
                    <img src={refresh} alt="refresh" />
                  </button>
                </div>
              </div>
              {showDetailsModal && <ModalEdit {...detailsModalProps} />}
              <motion.div className="scrollTable" variants={childVariants}>
                <BootstrapTable
                  remote={{ pagination: true, sort: true, search: true }}
                  bordered={false}
                  headerClasses="custom-header-class"
                  rowClasses="custom-row-class"
                  bodyClasses="custom-body-class"
                  selectRow={
                    tabSelected === 'Todas' || tabSelected === 'Con problemas'
                      ? noSelection
                      : selectRowModes
                  }
                  onTableChange={handleTableChange}
                  sort={{ dataField: 'date', order: 'desc' }}
                  {...paginationTableProps}
                  {...props.baseProps}
                />
              </motion.div>
            </div>
          </motion.div>
        )}
      </ToolkitProvider>
    </div>
  );
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton count={1} height={70} style={{ marginBottom: '10px' }} />
          <Skeleton count={1} height={30} style={{ marginBottom: '10px' }} />
          <Skeleton count={4} height={50} style={{ marginBottom: '10px' }} />
        </>
      ) : (
        <>
          <EditOrderWooModal
            isOpen={isEditOrderModalOpen}
            onClose={() => setIsEditOrderModalOpen(false)}
            onSave={patchOrder}
            currentPackageAmount={currentPackageAmount}
          />
          <PaginationProvider pagination={paginationFactory(optionsNew)}>
            {contentTable}
          </PaginationProvider>
        </>
      )}
    </>
  );
};
