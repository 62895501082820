import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { handleOrdersList, statusByTab } from '../../services/panel.service';
import { Table } from './Table';

export const GridSetter = ({ tab, ecommerce, rawData, updateTable }) => {
    const [ordersFilteredByStatus, setOrdersFilteredByStatus] = useState([]);
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        filterAndMapOrders(rawData);
    }, [tab])

    const filterByTabStatus = (rawData, tabSelected) => {
        if (!rawData || !tabSelected) return
        if (tabSelected === "pending") {
            return rawData.orders.filter(order => statusByTab.pendingTab.includes(order.trackingStatus));
        }
        if (tabSelected === "created") {
            return rawData.orders.filter(order => statusByTab.createdTab.includes(order.trackingStatus));
        }
        if (tabSelected === "delivered") {
            return rawData.orders.filter(order => statusByTab.deliveredTab.includes(order.trackingStatus));
        }
        if (tabSelected === "issue") {
            return rawData.orders.filter(order => statusByTab.issueTab.includes(order.trackingStatus));
        }
        if (tabSelected === "all") {
            return rawData.orders;
        }
    };
    
    const filterAndMapOrders = async (rawData) => {
        setisLoading(true);
        setOrdersFilteredByStatus([]);
        let filteredData = await filterByTabStatus(rawData, tab);
        if (filteredData && filteredData.length > 0) {
            const mappedData = await handleOrdersList(filteredData);
            setOrdersFilteredByStatus(mappedData);
        }
        setisLoading(false); 
    };

    return (
        <div className="grid-table">
            {isLoading ? 
                <>
                    <Skeleton count={1} height={70} style={{ marginBottom: "10px" }} />
                    <Skeleton count={1} height={30} style={{ marginBottom: "10px" }} />
                    <Skeleton count={4} height={50} style={{ marginBottom: "10px" }} />
                </>
            :
                <Table data={ordersFilteredByStatus} tabSelected={tab} updateTable={updateTable} ecommerce={ecommerce} originalLoading={isLoading}/>
            }
        </div>
    )
}
