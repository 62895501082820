import React, { useState, useEffect } from 'react';
import { CreationForm } from '../../../components/onboarding/tiendanube-only/CreationForm';
import { useAuthStore, useonboardingStore } from '../../../store';
import {motion} from 'framer-motion';
import { customerName } from '../../../data/InfoSource';
import { fadeInAndUpNoDelay } from '../../../services/shared/animation.service';
import { showError } from '../../../services/shared/alert.service';
import { getStoreName } from '../../../services/onboarding.service';

export const CreationFormConfig = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const user = useAuthStore((state) => state.authData.user);
    const addData = useonboardingStore((state) => state.addData);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(async() => {
        try {
            setIsLoading(true)
            const result = await getStoreName(user.storeId);
            if (result && result.success) {
                addData({
                store: {
                    locations: [
                      {
                        province: result.data.store?.province,
                        city: result.data.store?.city,
                        street: result.data.store?.street,
                        postalCode: result.data.store?.postalCode,
                        externalNumber: result.data.store?.externalNumber,
                        userId: result.data.store?.userId,
                        createAt: result.data.store?.createAt,
                        typeOfFee: result.data.store?.typeOfFee,
                        fee: result.data.store?.fee
                      },
                    ]
                }
                })
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
        }
    }, []);
    
    
    return (
        <>
        {!isLoading && 
            <div id="distributionCenterConfig">
                <motion.div variants={fadeInAndUpNoDelay} initial="hidden" animate="visible">
                    <h1 className="step-title fw-bold">Centros de distribución</h1>
                    <h2 className="step-subtitle">Agrega información extra a tu centro para incluir en <span className="fw-bold">{customerName}</span>.</h2>
                    
                    <CreationForm ecommerce={ecommerce} />
                </motion.div>
            </div>
        }
        </>
    )
}
