import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { LocationItem, LocationItemError, LocationItemNotConfiguredInEcommerce } from '../../components/onboarding/LocationItem';
import { OnboardingHeader } from '../../components/onboarding/OnboardingHeader';
import { ButtonSmall } from '../../components/units/Button';
import { InputsSwitch } from '../../components/units/Inputs';
import { useAuthStore, useonboardingStore } from '../../store';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { changeShopifyName } from '../../services/shared/utils.service';
import { saveDataVtexShopi } from '../../services/onboarding.service';
import { BiPlusCircle } from "react-icons/bi";
import refresh from '../../assets/refresh.svg';
import { showError, showSuccess } from '../../services/shared/alert.service';
import { motion } from 'framer-motion';
import { customerRouteName, customerName } from '../../data/InfoSource';
import { childVariants, fadeInAndRight, fadeInAndUp, growBtn } from '../../services/shared/animation.service';
import { getVtexUpdatedLocations } from '../../services/panel.service';

export const LocationList = () => {
    let history = useHistory();
    const addData = useonboardingStore((state) => state.addData);
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const user = useAuthStore((state) => state.authData.user);
    const [isLoading, setIsLoading] = useState(false);
    const [currentlocations, setCurrentlocations] = useState(store?.currentlocations);
    const [activationSwitch, setActivationSwitch] = useState(store?.activeAllSwitch);
    const [allActiveLocationsConfigurated, setAllActiveLocationsConfigurated] = useState(false);
    const [btnMessage, setbtnMessage] = useState("Debes activar al menos uno para continuar")
    const [showEmptyMsg, setshowEmptyMsg] = useState(false);

    useEffect(async () => {
        setIsLoading(true)
        if (store?.currentlocations?.locations.length > 0) {
            setCurrentlocations(store.currentlocations);
            setshowEmptyMsg(false)
        } else {
            setCurrentlocations({ locations: [] })
            setshowEmptyMsg(true)
        }
        setIsLoading(false)
    }, []);

    useEffect(() => {
        checkConfiguration();
    }, [currentlocations])

    const updateLocations = async () => {
        try {
            setIsLoading(true)
            let records = await getVtexUpdatedLocations("");
            if (records) {
                setshowEmptyMsg(false)
                checkConfiguration(records);
                setCurrentlocations(records)
                addData({
                    store: {
                        activeAllSwitch: activationSwitch,
                        currentlocations: records,
                    },
                });
            } else {
                setshowEmptyMsg(true)
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    const handleChange = (e, id, index) => {
        let stateCopy = currentlocations.locations;
        let selectedObj = stateCopy.slice(index, index + 1);
        selectedObj[0].active = e.target.checked;
        stateCopy[index] = selectedObj[0];
        setCurrentlocations({ locations: stateCopy });
    }

    const handleActiveAll = (e) => {
        if (e.target.checked) {
            let stateCopy = currentlocations.locations;
            stateCopy.forEach(loc => {
                if (loc.inCoverage) {
                    loc.active = true
                }
            })
            setCurrentlocations({ locations: stateCopy });
        } else {
            let stateCopy = currentlocations.locations;
            stateCopy.forEach(loc => {
                if (loc.inCoverage) {
                    loc.active = false
                }
            })
            setCurrentlocations({ locations: stateCopy });
        }
        setActivationSwitch(e.target.checked);
        addData({
            store: {
                ...store,
                activeAllSwitch: e.target.checked,
            },
        });
    }

    const GoNextPage = async () => {
        if (ecommerce === "vtex") {
            setIsLoading(true);
            try {
                const result = await saveDataVtexShopi(store?.currentlocations);
                if (result && result.success) {
                    showSuccess(result.message);
                    history.push(`/${customerRouteName}/${ecommerce}/finish`);
                }
                if (result?.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                showError("Ocurrió un error en la conexión");
            }
        } else {
            history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/rate`);
        }
    }

    const checkConfiguration = () => {
        let activeLocations = currentlocations.locations.filter(loc => loc.active === true);
        if (activeLocations && activeLocations.length !== 0) {
            if (activeLocations.length > 1) {
                if (activeLocations.every(loc => loc.firstName && loc.lastName && loc.phoneNumber && loc.email)) {
                    setAllActiveLocationsConfigurated(true)
                } else {
                    setAllActiveLocationsConfigurated(false)
                    setbtnMessage(`Configura todas tus tiendas activas para continuar`)
                }
            } else {
                if (activeLocations[0]?.firstName && activeLocations[0]?.lastName && activeLocations[0]?.phoneNumber && activeLocations[0]?.email) {
                    setAllActiveLocationsConfigurated(true)
                } else {
                    setAllActiveLocationsConfigurated(false)
                    setbtnMessage(`Configura todas tus tiendas activas para continuar`)
                }
            }
        } else {
            setAllActiveLocationsConfigurated(false)
            setbtnMessage("Debes activar al menos uno para continuar")
        }
    }

    return (
        <div className="container-fluid" id="locationList">
            <div className="row">
                <div className="col-4 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingHeader step={1} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-8 content-wrapper">
                    <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
                        <h1 className="step-title fw-bold">Tus tiendas</h1>
                        <h2 className="step-subtitle">Activa y agrega información extra a tus tiendas para incluir en <span className="fw-bold">{customerName}</span></h2>
                        <div className="ps-3 mt-4 mb-3 d-flex justify-content-between align-items-center">
                            <InputsSwitch
                                id="all"
                                checked={activationSwitch}
                                onChangeFn={handleActiveAll}
                                labelTxt={activationSwitch ? "Desactivar todas las tiendas" : "Activar todas las tiendas"}
                            />

                            {ecommerce === "vtex"
                                &&
                                <div className="d-flex justify-content-end align-items-center">
                                    <ButtonSmall
                                        type="button"
                                        btnTxt="Crear muelle"
                                        icon={<BiPlusCircle />}
                                        showSpinner={false}
                                        isDisabled={false}
                                        onClickFn={() => history.push(`/${customerRouteName}/vtex/create-center`)}
                                        extraClass="btn-outline-primary"
                                    />
                                    <button className="refresh ms-4" onClick={() => updateLocations()}>
                                        {isLoading ?
                                            <div className="spinner-grow spinner-grow-sm" role="status">
                                                <span className="visually-hidden">Cargando...</span>
                                            </div>
                                            :
                                            <img src={refresh} alt="refresh" />
                                        }
                                    </button>
                                </div>
                            }
                        </div>
                        {isLoading && (!store.currentlocations.locations.length > 0) ?
                            <>
                                <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
                                <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
                                <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
                                <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
                            </>
                            :
                            <div className="content-locations-list">
                                {showEmptyMsg && <div className="empty-msg"><p className="finish-small-title">Aún no hay tiendas disponibles</p></div>}
                                {ecommerce !== "shopify" ?
                                    <>
                                        {currentlocations?.locations.map((location, index) => (
                                            <motion.div variants={childVariants} key={index}>
                                                {location.inCoverage ?
                                                    <LocationItem
                                                        ecommerce={ecommerce}
                                                        index={index + 1}
                                                        id={location.locationId}
                                                        checked={location.active}
                                                        handleChange={(e) => handleChange(e, location.locationId, index)}
                                                        value
                                                        insidePanel={false}
                                                        {...location}
                                                    />
                                                    :
                                                    <LocationItemError
                                                        ecommerce={ecommerce}
                                                        index={index + 1}
                                                        id={location.locationId}
                                                        insidePanel={false}
                                                        {...location}
                                                    />
                                                }
                                            </motion.div>

                                        ))}
                                    </>
                                    :
                                    <>
                                        {currentlocations.locations.map((location, index) => (
                                            <motion.div variants={childVariants} key={index}>
                                                {location.ecommerceConfigured ?
                                                    <>
                                                        {location.inCoverage ?
                                                            <LocationItem
                                                                ecommerce={ecommerce}
                                                                index={index + 1}
                                                                id={location.locationId}
                                                                checked={location.active}
                                                                handleChange={(e) => handleChange(e, location.locationId, index)}
                                                                value
                                                                insidePanel={false}
                                                                {...location}
                                                            />
                                                            :
                                                            <LocationItemError
                                                                ecommerce={ecommerce}
                                                                index={index + 1}
                                                                id={location.locationId}
                                                                insidePanel={false}
                                                                {...location}
                                                            />
                                                        }
                                                    </>
                                                    :
                                                    <LocationItemNotConfiguredInEcommerce
                                                        index={index + 1}
                                                        {...location}
                                                    />
                                                }
                                            </motion.div>
                                        ))}
                                    </>
                                }
                            </div>
                        }
                    </motion.div>
                    <motion.div className="bottomPage" variants={growBtn} initial="hidden" animate="visible">
                        <ButtonSmall
                            ecommerce={ecommerce}
                            type="button"
                            onClickFn={GoNextPage}
                            btnTxt={allActiveLocationsConfigurated ? "Siguiente" : btnMessage}
                            showSpinner={false}
                            isDisabled={allActiveLocationsConfigurated ? false : true}
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    )
}
