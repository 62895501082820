import React, { useState } from 'react'
import { changeShopifyName, validateInputs } from '../../services/shared/utils.service';
import { useHistory } from 'react-router';
import { generalCoverage, getLocations, loginService, tiendanubeCoverage } from '../../services/onboarding.service';
import { showError, showSuccess } from '../../services/shared/alert.service';
import { useAuthStore, useonboardingStore } from '../../store';
import { ButtonBig } from '../units/Button';
import { InputsRadio, InputsSelect, InputsSimple } from '../units/Inputs';
import { motion } from 'framer-motion';
import { customerRouteName, customerName } from '../../data/InfoSource';
import { childVariants, growBigBtn } from '../../services/shared/animation.service';
import { setToken } from '../../services/storage';

export const LoginForm = ({ ecommerce }) => {
    const addAuth = useAuthStore((state) => state.addAuthData);
    const user = useAuthStore((state) => state.authData.user);
    const addData = useonboardingStore((state) => state.addData);
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({})
    const [body, setBody] = useState({
        customerApiKey: "",
        customerApiToken: "",
        password: "",
        country: "",
        shouldConvertZip: false,
    });



    const handleRadioChange = e => {
        setBody({ ...body, shouldConvertZip: !body.shouldConvertZip})
    }

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: (e.target.value).trim() })
    }

    const handleSelectChange = (e) => {
        setBody({ ...body, country: e.target.value })
    }

    const signIn = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            try {
                if (ecommerce === "vtex") {
                    let verifiedBody = body
                    if (body.country!=='CO'){
                        verifiedBody = {...body, shouldConvertZip: false}
                    }
                    const result = await loginService(verifiedBody, user.storeId, user.userId,);
                    if (result && result.success) {
                        setToken(result.token)
                        addAuth({
                            user: {
                                ...user,
                                customerApiKey: body.customerApiKey,
                                customerApiToken: body.customerApiToken,
                                country: body.country,
                            }
                        });
                        showSuccess(result.message);
                        const locList = await getLocations("");
                        if(locList && locList.success){
                            addData({
                                store: {
                                    activeAllSwitch: false,
                                    currentlocations: {
                                        locations: locList.locations
                                    },
                                },
                            });
                            const nextStep = user.fullIntegration 
                                ? `/${customerRouteName}/${changeShopifyName(ecommerce)}/location-list`
                                : `/${customerRouteName}/${changeShopifyName(ecommerce)}/finish`
                            ;
                            history.push(nextStep);
                        } else {
                            console.log("No se recibieron las locations");
                        }
                    }
                } else {
                    const result = await loginService(body, user.storeId);
                    if (result && result?.success) {
                        addAuth({
                            user: {
                                ...user,
                                customerApiKey: body.customerApiKey,
                                customerApiToken: body.customerApiToken,
                                country: body.country
                            }
                        });
                        showSuccess(result.message);
                        history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/location-list`)
                    }
                    if (result?.error) {
                        showError(result.error);
                    }
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <form onSubmit={signIn}>
            <motion.div variants={childVariants} className="mb-2 mb-xxl-3">
                <InputsSimple   
                value={body.customerApiKey}
                id="customerApiKey"
                type="text"
                placeholder=""
                labelTxt="API Key"
                onChangeFn={handleChange}
                resultValidation = {formErrors?.customerApiKey ? "is-invalid" : ""}
                />
                {formErrors.customerApiKey && <span className="invalid-msg">{formErrors.customerApiKey}</span> }
            </motion.div>

            <motion.div variants={childVariants} className="mb-2 mb-xxl-3">
                <InputsSimple
                value={body.customerApiToken}
                id="customerApiToken"
                type="text"
                placeholder=""
                labelTxt="API Token"
                onChangeFn={handleChange}
                resultValidation={formErrors?.customerApiToken ? "is-invalid" : ""}
                />
                {formErrors.customerApiToken && <span className="invalid-msg">{formErrors.customerApiToken}</span> }
            </motion.div>

            {
                ecommerce==="vtex" &&
                <motion.div variants={childVariants} className="mb-2 mb-xxl-3">
                    <InputsSimple 
                    value={body.password}
                    id="password"
                    type="password"
                    placeholder=""
                    labelTxt={`Crea tu contraseña para el panel VTEX - ${customerName}`}
                    onChangeFn={handleChange}
                    resultValidation={formErrors?.password ? "is-invalid" : ""}
                    />
                    {formErrors.password && <span className="invalid-msg">{formErrors.password}</span> }
                </motion.div>
            }

            <motion.div variants={childVariants} className='mb-1 mb-xxl-3'>
                <InputsSelect
                id="country"
                labelTxt="País"
                onChangeFn={handleSelectChange}
                arrayList={ecommerce==="tiendanube" ? tiendanubeCoverage : generalCoverage}
                previouslySelected={false}
                resultValidation={formErrors?.country ? "is-invalid" : ""}
                />
                {formErrors.country && <span className="invalid-msg">{formErrors.country}</span> }
            </motion.div>
            <motion.article id='postalcode-selector'>
                {(body.country==='CO' && ecommerce==='vtex') &&
                <>
                <label className='title-postalcode'>¿Cómo quieres los formatos de los códigos postales? </label>
                <div className="d-flex flex-row">
                    <InputsRadio
                    id="fiveDigits"
                    name="postalCode"
                    extraClassname='mb-0 radio-margin-bottom margin-fixed-fee'
                    value="postalCode"
                    labelTxt="Cinco dígitos."
                    onChangeFn={handleRadioChange}
                    checked={body.shouldConvertZip}
                    />
                    <InputsRadio
                    id="sixDigits"
                    name="postalCode"
                    extraClassname=' ms-5 mt-0 radio-margin-bottom margin-fixed-fee'
                    value="fixed"
                    labelTxt="Seis dígitos."
                    onChangeFn={handleRadioChange}
                    checked={body.shouldConvertZip===false}
                    />
                </div>
                
                </>
                }

            </motion.article>
            <motion.div className="loginButton" variants={growBigBtn}>
                <ButtonBig 
                ecommerce={ecommerce}
                type="submit"
                btnTxt="Ingresar"
                showSpinner={isLoading}
                isDisabled={( (ecommerce!=="vtex" && body.customerApiKey && body.customerApiToken && body.country) || (ecommerce==="vtex" && body.customerApiKey && body.customerApiToken && body.country && body.password) ) ? false : true}
                />
            </motion.div>
        </form>
    )
}
