export const mockLocations = {
    fee: {
        type: "fixed",
        amount: 101
    },
    locations: [
        {
            active: true,
            name: "Nombre personalizado",
            locationId: "65924169958",
            country: "Mexico",
            country_code: "MX",
            province: "Ciudad de México",
            city: "Ciudad de México",
            address: "Av. 20 de Noviembre",
            address2: "",
            zip: "06030",
            inCoverage: true,
            deliveryPromise: {
                from: 0,
                to: 0,
                isBusinessDay: true,
            },
            firstName: "NombrePrueba",
            lastName: "ApellidoPrueba",
            phoneNumber: "123",
            email: "email@email",
            notes: "No funciona el timbre",
            ecommerceConfigured: true,
        },
        {
            active: false,
            name: "",
            locationId: "65924169959",
            country: "Mexico",
            country_code: "MX",
            province: "Ciudad de México",
            city: "Ciudad de México",
            address: "C. de Venustiano Carranza 125",
            address2: "",
            zip: "06030",
            inCoverage: true,
            deliveryPromise: {
                from: 0,
                to: 0,
                isBusinessDay: true,
            },
            ecommerceConfigured: false,
        },
        {
            active: false,
            name: "Nombre personalizado",
            locationId: "65924169960",
            country: "Argentina",
            country_code: "AR",
            province: "Buenos Aires",
            city: "Capital Federal",
            address: "Av. Libertador 2532",
            address2: "",
            zip: "1428",
            inCoverage: false,
            deliveryPromise: {
                from: 0,
                to: 0,
                isBusinessDay: true,
            },
            ecommerceConfigured: true,
        },
        {
            active: false,
            name: "",
            locationId: "65924169961",
            country: "Argentina",
            country_code: "AR",
            province: "Buenos Aires",
            city: "Capital Federal",
            address: "Alberti 1598",
            address2: "",
            zip: "1428",
            inCoverage: false,
            deliveryPromise: {
                from: 0,
                to: 0,
                isBusinessDay: true,
            },
            ecommerceConfigured: false,
        },
    ],
}

export const tnMockLocation = {
    locations: [
        {
            province: "Buenos Aires",
            city: "Capital Federal",
            street: "Corrientes",
            postalCode: "1425",
            externalNumber: "1400",
            userId: "123",
            createAt: "panel",
            typeOfFee: "fixed",
            // adjustedFee: "",
            fee: 90
        },
    ]
}

export const mockOrders = {
    "success": true,
    "merchantType": "prepay-dropoff",
    "country": "AR",
    "orders": [
        {
            "orderNumber": 1103,
            "orderId": "61954b6ad8b38d7f4b7d7cb1",
            "trackingStatus": "waitingDropoff",
            "trackingId": "KSTB5RJP9WI5A4L4F7TL",
            "trackingUrl": "",
            "label": "",
            "error": [],
            "date": "2022-03-04T18:35:22.833Z",
            "mode": "Domicilio",
            "isNew": false,
            "preferenceId": ""
        },
        {
            "orderNumber": 1104,
            "orderId": "619558a666f15dd5507f0978",
            "trackingStatus": "pending",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": [],
            "date": "2022-03-04T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": false,
            "preferenceId": "123456"
        },
        {
            "orderNumber": 1105,
            "orderId": "619558a666f62hhfg4fg55",
            "trackingStatus": "label created",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": [],
            "date": "2022-03-04T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": true,
            "preferenceId": ""
        },
        {
            "orderNumber": 1106,
            "orderId": "619558a666f62hhfg48882",
            "trackingStatus": "label created",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": [],
            "date": "2022-03-03T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": false,
            "preferenceId": "123456"
        },
        {
            "orderNumber": 1107,
            "orderId": "619558a666f62hhfg4896",
            "trackingStatus": "pendingPayment",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": [],
            "date": "2022-03-03T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": false,
            "preferenceId": ""
        },
        {
            "orderNumber": 1108,
            "orderId": "5",
            "trackingStatus": "error",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": "Hay un error en teléfono",
            "date": "2022-03-03T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": false,
            "preferenceId": ""
        },
        {
            "orderNumber": 1109,
            "orderId": "619558a666f62hhfg4894",
            "trackingStatus": "cancelled",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": [],
            "date": "2022-03-03T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": false,
            "preferenceId": ""
        },
        {
            "orderNumber": 1110,
            "orderId": "619558a666f62hhfg4893",
            "trackingStatus": "delivered",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": [],
            "date": "2022-03-03T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": false,
            "preferenceId": ""
        },
        {
            "orderNumber": 1111,
            "orderId": "619558a666f62hhfg4891",
            "trackingStatus": "delivered",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": [],
            "date": "2022-03-03T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": false,
            "preferenceId": ""
        },
        {
            "orderNumber": 1112,
            "orderId": "619558a666f62hhfg4892",
            "trackingStatus": "crossdock",
            "trackingId": "HDTO36PN1AV0TPQ0ATU1",
            "label": "",
            "error": [],
            "date": "2022-03-03T18:35:22.833Z",
            "mode": "Punto de Retiro",
            "isNew": false,
            "preferenceId": ""
        }
    ]
}

export const mockClientInfo = {
    orderInfo: {
        email: "juan@gmail.com",
        first_name: "Juan",
        last_name: "Grasso",
        country: "MX",
        administrative_area: "Baja California", // Estado
        sub_administrative_area: "La Paz", // Ciudad
        address_line_1: "Simón Bolívar 449",
        postal_code: "06800",
        notes: "Aplaudir porque no funciona el timbre"
    }
}

export const vtexShopiMockLocations = {
    fee: {
        type: "fixed",
        amount: 200,
        promotionalFreeShipping: {
            active: false,
            amount: 0
        },
        promotionalDynamicFee: {
            active: false,
            amount: 0,
        }
    },
    locations: [
        {
            "inCoverage": true,
            "name": "Muelle de prueba",
            "vtexLocationId": "REB5CE1PhX4IHT_C8khz0",
            "active": true,
            "locationId": "REB5CE1PhX4IHT_C8khz0",
            "country": "Argentina",
            "country_code": "AR",
            "province": "Buenos Aires",
            "city": "Buenos Aires",
            "address": "Av Directorio 1316",
            "address2": "",
            "zip": "01406",
            "ecommerceConfigured": true,
            "firstName": "nombre falso",
            "lastName": "apellido falso",
            "phoneNumber": "123456789",
            "notes": "notas",
            "email": "email@email.com",
        },
        {
            "inCoverage": true,
            "name": "Muelle México",
            "vtexLocationId": "mx-dock",
            "active": false,
            "locationId": "mx-dock",
            "country": "México",
            "country_code": "MX",
            "province": "Ciudad de México",
            "city": "MIGUEL HIDALGO",
            "address": "Bahia Montejo 79",
            "address2": "",
            "zip": "11300",
            "ecommerceConfigured": true
        },
        {
            "inCoverage": false,
            "name": "Tiendita2",
            "vtexLocationId": "37SZ9vZ1Rtt8RSnvt3Sf0",
            "active": false,
            "locationId": "37SZ9vZ1Rtt8RSnvt3Sf0",
            "country": "Mexico",
            "country_code": "MX",
            "province": "Distrito",
            "city": "Distrito federal",
            "address": "Londres 4567",
            "address2": "",
            "zip": "13319",
            "ecommerceConfigured": false
        }
    ]
}

export const pointsMockData = {
    success: true,
    points: [
        {
            name: "Scarlett Soho",
            street: "Nicaragua",
            streetNumber: "4457",
            localidad: "Buenos Aires, Ciudad Autónoma de Buenos Aires",
            telefono: "+123456789",
            // hours: "lunes a viernes 9:00 - 19:00",
            latitud: -34.5891729,
            longitud: -58.423744,
        },
        {
            name: "Scarlett Centro",
            street: "Paraguay",
            streetNumber: "639",
            localidad: "Buenos Aires, Ciudad Autónoma de Buenos Aires",
            telefono: "+123456789",
            // hours: "lunes a viernes 9:00 - 19:00",
            latitud: -34.597412,
            longitud: -58.375819999999976,
        },
        {
            name: "Scarlett República",
            street: "República de la India",
            streetNumber: "2907",
            localidad: "Buenos Aires, Ciudad Autónoma de Buenos Aires",
            telefono: "+123456789",
            // hours: "lunes a viernes 9:00 - 19:00",
            latitud: -34.579127,
            longitud: -58.41531900000001,
        },
    ]
}