import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomerShopifyRoute from "./shopify/customerShopifyRoute";
import CustomerVTEXRoute from "./vtex/customerVTEXRoute";
import CustomerTNRoute from "./tiendanube/customerTNRoute";
import CustomerWooRoute from "./woo/customerWooRoute";

export default function CustomerRoute({ rootCustomerPath }) {
  return (
    <>
      <Switch>
        <Route
          path={`/${rootCustomerPath}/secommerce`}
          render={() => (
            <CustomerShopifyRoute rootCustomerPath={rootCustomerPath} />
          )}
        />
        <Route
          path={`/${rootCustomerPath}/vtex`}
          render={() => (
            <CustomerVTEXRoute rootCustomerPath={rootCustomerPath} />
          )}
        />
        <Route
          path={`/${rootCustomerPath}/tiendanube`}
          render={() => <CustomerTNRoute rootCustomerPath={rootCustomerPath} />}
        />
        <Route
          path={`/${rootCustomerPath}/woo`}
          render={() => (
            <CustomerWooRoute rootCustomerPath={rootCustomerPath} />
          )}
        />
      </Switch>
    </>
  );
}
