import {
  post as restPost,
  get as restGet,
} from "../services/shared/rest.service";

//propio de Tiendanube
export const getStoreName = async (storeId) => {
  try {
    const endpoint = `/onboarding/initial-state?shop=${storeId}`;
    const result = await restGet(endpoint);

    return result;
  } catch (error) {
    throw error.message;
  }
};

//propio de Woo
export const signInWoo = async (token) => {
  try {
    const endpoint = `/api/login`;
    const body = {
      storeKey: token,
    };
    const result = await restPost(endpoint, body);

    return result;
  } catch (error) {
    console.log({ error });
    throw error.message;
  }
};

//propios de Vtex
export const signIn = async (params) => {
  try {
    const endpoint = `/auth/login`;
    const body = {
      vtexUrl: params.storeUrl,
      password: params.password,
    };
    const result = await restPost(endpoint, body);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const vtexIntegration = async (
  apiKey,
  apiSecret,
  storeUrl,
  fullIntegration
) => {
  try {
    const endpoint = `/onboarding/vtex`;
    const body = {
      vtexKey: apiKey,
      vtexToken: apiSecret,
      vtexUrl: storeUrl,
      fullIntegration,
    };
    const result = await restPost(endpoint, body);

    return result;
  } catch (error) {
    throw error.message;
  }
};

export const createLocation = async (params) => {
  try {
    const endpoint = `/onboarding/location`;
    const result = await restPost(endpoint, params);

    return result;
  } catch (error) {
    throw error.message;
  }
};

//compartidos
export const loginService = async (params, storeId, userId) => {
  try {
    const endpoint = `/onboarding/authentication?shop=${storeId}`;
    const body = {
      apiKey: params.customerApiKey,
      token: params.customerApiToken,
      password: params.password || "",
      country: params.country,
      shouldConvertZip: params.shouldConvertZip,
      userId: userId || "",
    };
    const result = await restPost(endpoint, body);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const getLocations = async (storeId = "") => {
  try {
    const endpoint = `/onboarding/locations?shop=${storeId}`;
    const result = await restGet(endpoint);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const configLocation = async (params, storeId) => {
  try {
    const endpoint = `/onboarding/locations?shop=${storeId}`;
    const result = await restPost(endpoint, params);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const saveData = async (params, storeId) => {
  try {
    const endpoint = `/onboarding/ship-creation?shop=${storeId}`;
    const result = await restPost(endpoint, params);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const saveDataVtexShopi = async (params, storeId) => {
  try {
    const endpoint = `/onboarding/locations?shop=${storeId}`;
    const result = await restPost(endpoint, params);
    return result;
  } catch (error) {
    throw error.message;
  }
};

export const tiendanubeCoverage = [
  {
    name: "Argentina",
    value: "AR",
  },
  {
    name: "Chile",
    value: "CL",
  },
  {
    name: "Colombia",
    value: "CO",
  },
  {
    name: "México",
    value: "MX",
  },
];

export const generalCoverage = [
  {
    name: "Argentina",
    value: "AR",
  },
  {
    name: "Chile",
    value: "CL",
  },
  {
    name: "Colombia",
    value: "CO",
  },
  {
    name: "México",
    value: "MX",
  },
  {
    name: "Perú",
    value: "PE",
  },
  {
    name: "Uruguay",
    value: "UY",
  },
];
