import React from 'react';
import { NavLink } from "react-router-dom";
import { FiBox, FiShoppingBag, FiDollarSign, FiMapPin, FiTruck, FiSettings, FiUser } from "react-icons/fi";

import { customerRouteName } from '../../data/InfoSource';
import { useAuthStore } from '../../store';

export const PanelMainMenu = ({ ecommerce }) => {
    const { user } = useAuthStore(state => state.authData);

    const shopifyPages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/secommerce/panel/order/all`, icon: <FiBox/> },
        { title: "Tus tiendas", routeInfo: `/${customerRouteName}/secommerce/panel/location-list`, icon: <FiShoppingBag/> },
        { title: "Tarifa", routeInfo: `/${customerRouteName}/secommerce/panel/rate`, icon: <FiDollarSign/> },
        { title: "Puntos de despacho", routeInfo: `/${customerRouteName}/secommerce/panel/dropoff-points`, icon: <FiTruck/> },
        { title: "Puntos de entrega", routeInfo: `/${customerRouteName}/secommerce/panel/pickup-points`, icon: <FiMapPin/> },
        { title: "Configuraciones", routeInfo: `/${customerRouteName}/secommerce/panel/settings`, icon: <FiSettings/> },
    ];

    const tiendanubePages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/tiendanube/panel/order/all`, icon: <FiBox/> },
        { title: "Tu tienda", routeInfo: `/${customerRouteName}/tiendanube/panel/location-list`, icon: <FiShoppingBag/> },
        { title: "Tarifa y creación", routeInfo: `/${customerRouteName}/tiendanube/panel/rate`, icon: <FiDollarSign/> },
        { title: "Puntos de despacho", routeInfo: `/${customerRouteName}/tiendanube/panel/dropoff-points`, icon: <FiTruck/> },
        { title: "Puntos de entrega", routeInfo: `/${customerRouteName}/tiendanube/panel/pickup-points`, icon: <FiMapPin/> },
    ];

    const vtexPages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/vtex/panel/order/all`, icon: <FiBox/> },
        { title: "Tus tiendas", routeInfo: `/${customerRouteName}/vtex/panel/location-list`, icon: <FiShoppingBag/> },
        { title: "Puntos de despacho", routeInfo: `/${customerRouteName}/vtex/panel/dropoff-points`, icon: <FiTruck/> },
        { title: "Puntos de entrega", routeInfo: `/${customerRouteName}/vtex/panel/pickup-points`, icon: <FiMapPin/> },
        { title: "Cuenta", routeInfo: `/${customerRouteName}/vtex/panel/account`, icon: <FiUser/> },
    ];

    const wooPages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/woo/panel/order/all`, icon: <FiBox/> },
        { title: "Puntos de despacho", routeInfo: `/${customerRouteName}/woo/panel/dropoff-points`, icon: <FiTruck/> },
        { title: "Puntos de entrega", routeInfo: `/${customerRouteName}/woo/panel/pickup-points`, icon: <FiMapPin/> },
    ];

    if (ecommerce === "vtex" && !user.fullIntegration) {
        vtexPages.splice(0,1);
    }

    return (
        <>
        {ecommerce === "shopify" &&
            <div className="panel-menu">
                {shopifyPages.map( (navItem, index) => (
                    <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`} key={index}>
                    {navItem.icon} {navItem.title}
                    </NavLink>)
                )}
            </div>
        }
        {ecommerce === "tiendanube" &&
            <div className="panel-menu">
                {tiendanubePages.map( (navItem, index) => (
                    <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`} key={index}>
                    {navItem.icon} {navItem.title}
                    </NavLink>)
                )}
            </div>
        }
        {ecommerce === "vtex" &&
            <div className="panel-menu">
                {vtexPages.map( (navItem, index) => (
                    <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`} key={index}>
                    {navItem.icon} {navItem.title}
                    </NavLink>)
                )}
            </div>
        }
        { ecommerce === "woo" &&
            <div className="panel-menu">
                {wooPages.map( (navItem, index) => (
                    <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`} key={index}>
                    {navItem.icon} {navItem.title}
                    </NavLink>)
                )}
            </div>
        }
        </>
    )
}