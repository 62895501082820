import argFlag from '../assets/flags/flag_arg.svg';
import mexFlag from '../assets/flags/flag_mex.svg';
import chiFlag from '../assets/flags/flag_chi.svg';
import colFlag from '../assets/flags/flag_col.svg';
import perFlag from '../assets/flags/flag_per.svg';
import uruFlag from '../assets/flags/flag_uru.svg';

export const customerName = "pickit"

export const customerRouteName = "pickit"

const urlInfo = {
    argentina: {
        name: "Argentina",
        flag: argFlag,
        createAccount: "https://pickit.com.ar/pickit-para-ecommerce.html",
        contactUs: "contacto.ar@pickit.net",
    },
    mexico: {
        name: "México",
        flag: mexFlag,
        createAccount: "https://pickit.com.mx/pickit-para-ecommerce.html",
        contactUs: "contacto.mx@pickit.net",
    },
    chile: {
        name: "Chile",
        flag: chiFlag,
        createAccount: "https://pickit.cl/pickit-para-ecommerce.html",
        contactUs: "contacto.cl@pickit.net",
    },
    colombia: {
        name: "Colombia",
        flag: colFlag,
        createAccount: "https://pickit.com.co/pickit-para-ecommerce.html",
        contactUs: "contacto.co@pickit.net",
    },
    peru: {
        name: "Perú",
        flag: perFlag,
        createAccount: "https://pickit.com.pe/pickit-para-ecommerce-html",
        contactUs: "contacto.pe@pickit.net",
    },
    uruguay: {
        name: "Uruguay",
        flag: uruFlag,
        createAccount: "https://pickit.com.uy/pickit-para-ecommerce.html",
        contactUs: "contacto.uy@pickit.net",
    },
}

export const countryInfoByEcommerce = {
    shopify: [
        urlInfo.argentina,
        urlInfo.mexico,
        urlInfo.chile,
        urlInfo.colombia,
        urlInfo.peru,
        urlInfo.uruguay,
    ],
    tiendanube: [
        urlInfo.argentina,
        urlInfo.mexico,
        urlInfo.chile,
        urlInfo.colombia,
    ],
    vtex: [
        urlInfo.argentina,
        urlInfo.mexico,
        urlInfo.chile,
        urlInfo.colombia,
        urlInfo.peru,
        urlInfo.uruguay,
    ]
}

export const maxInputLength = 900;
