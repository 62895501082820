import React, { useState } from "react";
import { Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import CustomerRoute from "./customer/customerRoute";
import { useAuthStore } from "../store";
import { showError } from "../services/shared/alert.service";
import { setEcommerceURL } from "../services/shared/utils.service";

import { customerRouteName } from "../data/InfoSource";
import { configAxiosRequest, configAxiosResponse } from "../services/axios";

export default function AppRoute() {
  const location = useLocation();
  let history = useHistory();
  const getAuthData = useAuthStore((state) => state.getAuthData);
  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
  const removeVtexAuthMinusEcommerce = useAuthStore(
    (state) => state.removeVtexAuthDataKeepEcommerce
  );
  
  configAxiosRequest({ user: getAuthData().user });

  configAxiosResponse({
    ecommerce,
    removeVtexAuthMinusEcommerce,
    showError,
    history,
    customerRouteName,
  });

  return (
    <>
      <Route
        path={`/${customerRouteName}`}
        render={() => <CustomerRoute rootCustomerPath={customerRouteName} />}
      />
    </>
  );
}
