import React from 'react';
import { OnboardingHeader } from '../../../components/onboarding/OnboardingHeader';
import { CreateNewCenter } from '../../../components/onboarding/vtex-only/CreateNewCenter';
import { useAuthStore } from '../../../store';
import { motion } from 'framer-motion';
import { fadeInAndRight, fadeInAndUp } from '../../../services/shared/animation.service';

export const CreateCenter = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);

    return (
        <div className="container-fluid" id="distributionCenter">
            <div className="row">
                <div className="col-4 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        {/* TODO: only 1 */}
                        <OnboardingHeader step={ecommerce==="vtex" ? 2 : 1} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-8 content-wrapper">
                    <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
                    <h1 className="step-title fw-bold mb-1 mb-xl-2 mb-xxl-3">Creación de muelle</h1>
                    </motion.div>
                    
                    <CreateNewCenter ecommerce={ecommerce} />
                </div>
            </div>
        </div>
    )
}