import React from "react";
import { Route, Switch } from "react-router-dom";
import { Welcome } from "../../../pages/onboarding/Welcome";
import { LocationList } from "../../../pages/onboarding/LocationList";
import { LocationSingle } from "../../../pages/onboarding/LocationSingle";
import { DeliveryPromise } from "../../../pages/onboarding/DeliveryPromise";
import { Finish } from "../../../pages/onboarding/Finish";
import PanelRoute from "../panel/panelRoute";

export default function CustomerShopifyRoute({ rootCustomerPath }) {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`/${rootCustomerPath}/secommerce/welcome`}
          component={Welcome}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/secommerce/location-list`}
          component={LocationList}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/secommerce/location-list/:id`}
          component={LocationSingle}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/secommerce/rate`}
          component={DeliveryPromise}
        />
        <Route
          exact
          path={`/${rootCustomerPath}/secommerce/finish`}
          component={Finish}
        />

        <Route
          path={`/${rootCustomerPath}/secommerce/panel`}
          render={() => <PanelRoute rootCustomerPath={rootCustomerPath} />}
        />

        <Route
          exact
          path={`/${rootCustomerPath}/secommerce`}
          component={Welcome}
        />
      </Switch>
    </>
  );
}
