import { Route, Switch } from "react-router-dom";
import { Loader } from "../../../pages/onboarding/Loader";
import { Welcome } from "../../../pages/onboarding/Welcome";
import PanelRoute from "../panel/panelRoute";


export default function CustomerWOORoute({ rootCustomerPath }) {
  return (
    <>
        <Switch>
          <Route exact path={`/${rootCustomerPath}/woo/loader`} component={Loader} />
          <Route path={`/${rootCustomerPath}/woo/panel`} render={() => <PanelRoute rootCustomerPath={rootCustomerPath} /> }/> 
          <Route exact path={`/${rootCustomerPath}/woo`} component={Welcome} />
        </Switch>
    </>
  );
}
