import React, { useState, useEffect } from 'react'
import { DeliveryTimesForm } from '../../../components/onboarding/tiendanube-only/DeliveryTimesForm';
import { getStoreName } from '../../../services/onboarding.service';
import { showError } from '../../../services/shared/alert.service';
import { useAuthStore, useonboardingStore } from '../../../store';

export const DeliveryTimesConfig = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const user = useAuthStore((state) => state.authData.user);
    const addData = useonboardingStore((state) => state.addData);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(async() => {
        try {
            setIsLoading(true)
            const result = await getStoreName(user.storeId);
            if (result && result.success) {
                addData({
                store: {
                    locations: [
                      {
                        province: result.data.store?.province,
                        city: result.data.store?.city,
                        street: result.data.store?.street,
                        postalCode: result.data.store?.postalCode,
                        externalNumber: result.data.store?.externalNumber,
                        userId: result.data.store?.userId,
                        createAt: result.data.store?.createAt,
                        typeOfFee: result.data.store?.typeOfFee,
                        fee: result.data.store?.fee
                      },
                    ]
                }
                })
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
        }
    }, []);

    return (
        <>
        {!isLoading && 
            <div id="deliveryConfig">
                <DeliveryTimesForm ecommerce={ecommerce}/>
            </div>
        }
        </>
    )
}
