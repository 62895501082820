import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { vtexIntegration } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { validateInputs } from '../../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { useAuthStore } from '../../../store';
import { ButtonBig } from '../../units/Button';
import { InputsSimple } from '../../units/Inputs';
import { childVariants, growBigBtn } from '../../../services/shared/animation.service';
import { customerRouteName } from '../../../data/InfoSource';

export const VtexIntegrationForm = ({ ecommerce }) => {
    const addAuth = useAuthStore((state) => state.addAuthData);
    const user = useAuthStore((state) => state.authData.user);

    let history = useHistory();

    const [isLoading, setIsLoading] = useState("");
    const [formErrors, setFormErrors] = useState({})
    const [body, setBody] = useState({
        apiKey: "",
        apiSecret: "",
        storeUrl: "",
        fullIntegration: user.fullIntegration,
    });

    useEffect(() => {
        addAuth({
            user: {
                ...user,
                apiKey: "",
                apiSecret: "",
                storeUrl: "",
            }
        });
    }, [])
    
    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: (e.target.value).trim() })
    }

    const configVtexKeys = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            try{
                const result = await vtexIntegration(
                    body.apiKey, 
                    body.apiSecret, 
                    body.storeUrl, 
                    body.fullIntegration
                );
                if(result && result.success) {
                    addAuth({
                        user: {
                            ...user,
                            apiKey: body.apiKey,
                            apiSecret: body.apiSecret,
                            storeUrl: body.storeUrl,
                            userId: result.userId,
                            fullIntegration: body.fullIntegration,
                        }
                    });
                    showSuccess(result.message);
                    history.push(`/${customerRouteName}/vtex/welcome`);
                }
                if(result.error){
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error){
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <form onSubmit={configVtexKeys}>
            <motion.div variants={childVariants} className="mb-2 mb-xxl-3">
                <InputsSimple 
                value={body.apiKey}
                id="apiKey"
                type="text"
                placeholder="xxxx-xxxx-XXXXX"
                labelTxt="App key"
                onChangeFn={handleChange}
                resultValidation = {formErrors?.apiKey ? "is-invalid" : ""}
                />
                {formErrors.apiKey && <span className="invalid-msg">{formErrors.apiKey}</span> }
            </motion.div>

            <motion.div variants={childVariants} className="mb-2 mb-xxl-3">
                <InputsSimple 
                value={body.apiSecret}
                id="apiSecret"
                type="text"
                placeholder="XXXXXXXXXXXXXXXX"
                labelTxt="App token"
                onChangeFn={handleChange}
                resultValidation = {formErrors?.apiSecret ? "is-invalid" : ""}
                />
                {formErrors.apiSecret && <span className="invalid-msg">{formErrors.apiSecret}</span> }
            </motion.div>

            <motion.div variants={childVariants} className="mb-2 mb-xxl-3">
                <InputsSimple 
                value={body.storeUrl}
                id="storeUrl"
                type="text"
                placeholder="Ejemplo: https://nombredetienda.myvtex.com"
                labelTxt="URL de la tienda"
                onChangeFn={handleChange}
                resultValidation = {formErrors?.storeUrl ? "is-invalid" : ""}
                />
                {formErrors.storeUrl && <span className="invalid-msg">{formErrors.storeUrl}</span> }
            </motion.div>

            <motion.div variants={growBigBtn}>
                <ButtonBig 
                ecommerce={ecommerce}
                type="submit"
                btnTxt="Crear cuenta"
                showSpinner={isLoading}
                isDisabled={(body.apiKey && body.apiSecret && body.storeUrl) ? false : true}
                />
            </motion.div>
        </form>
    )
}
