import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LocationItem, LocationItemError, LocationItemNotConfiguredInEcommerce } from '../../components/onboarding/LocationItem';
import { InputsSwitch } from '../../components/units/Inputs';
import { useAuthStore, useonboardingStore } from '../../store';
import { getLocations, saveDataVtexShopi } from '../../services/onboarding.service';
import { showError, showSuccess } from '../../services/shared/alert.service';
import { ButtonSmall } from '../../components/units/Button';
import refresh from '../../assets/refresh.svg'
import { BiPlusCircle } from "react-icons/bi";
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { changeShopifyName } from '../../services/shared/utils.service';
import { customerRouteName, customerName } from '../../data/InfoSource';
import { childVariants, fadeInAndUp, fadeInAndUpNoDelay, growBtnSmallDelay } from '../../services/shared/animation.service';
import { getVtexUpdatedLocations } from '../../services/panel.service';
import { mockLocations } from '../../data/mockData';

export const LocationsConfig = () => {
    const addData = useonboardingStore((state) => state.addData);
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const user = useAuthStore((state) => state.authData.user);
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [currentlocations, setCurrentlocations] = useState({});
    const [activationSwitch, setActivationSwitch] = useState(store?.activeAllSwitch);
    const [allActiveLocationsConfigurated, setAllActiveLocationsConfigurated] = useState(false);
    const [btnMessage, setbtnMessage] = useState("Debes activar al menos uno para continuar");
    const [showEmptyMsg, setshowEmptyMsg] = useState(false);

    useEffect(async () => {
        if (Object.keys(currentlocations).length === 0) {
            updateLocations();
        }
    }, []);

    const updateLocations = async () => {
        try {
            setIsLoading(true)
            let records = {};
            if (ecommerce === "vtex") {
                records = await getVtexUpdatedLocations("");
            }else if (ecommerce === "woo") {
                records = mockLocations;
            } else {
                records = await getLocations(user.storeId);
            }
            if (records) {
                setshowEmptyMsg(false)
                checkConfiguration(records);
                setCurrentlocations(records)
                addData({
                    store: {
                        currentlocations: records,
                    },
                });
            } else {
                setshowEmptyMsg(true)
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
        }
    }

    const handleChange = (e, id, index) => {
        let stateCopy = currentlocations.locations;
        let selectedObj = stateCopy.slice(index, index + 1);
        selectedObj[0].active = e.target.checked;
        stateCopy[index] = selectedObj[0];
        setCurrentlocations({ locations: stateCopy });
        checkConfiguration(currentlocations);
    }

    const handleActiveAll = (e) => {
        if (e.target.checked) {
            let stateCopy = currentlocations.locations;
            stateCopy.forEach(loc => {
                if (loc.inCoverage) {
                    loc.active = true
                }
            })
            setCurrentlocations({ locations: stateCopy });
        } else {
            let stateCopy = currentlocations.locations;
            stateCopy.forEach(loc => {
                if (loc.inCoverage) {
                    loc.active = false
                }
            })
            setCurrentlocations({ locations: stateCopy });
        }
        checkConfiguration(currentlocations);
        setActivationSwitch(e.target.checked);
        addData({
            store: {
                ...store,
                activeAllSwitch: e.target.checked,
            },
        });
    }

    const saveNewLocationsConfig = async () => {
        try {
            setIsLoading(true);
            const result = await saveDataVtexShopi(currentlocations, user.storeId);
            if (result && result.success) {
                showSuccess(result.message);
                if (ecommerce === "shopify") {
                    history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/panel/rate`)
                }
            }
            if (result?.error) {
                showError(result.error);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showError(error);
        }
    }

    const checkConfiguration = (locationData) => {
        let activeLocations = locationData.locations.filter(loc => loc.active === true);
        if (activeLocations && activeLocations.length !== 0) {
            if (activeLocations.length > 1) {
                if (activeLocations.every(loc => loc.firstName && loc.lastName && loc.phoneNumber && loc.email)) {
                    setAllActiveLocationsConfigurated(true)
                } else {
                    setAllActiveLocationsConfigurated(false)
                    setbtnMessage(`Configura todas tus tiendas activas para continuar`)
                }
            } else {
                if (activeLocations[0]?.firstName && activeLocations[0]?.lastName && activeLocations[0]?.phoneNumber && activeLocations[0]?.email) {
                    setAllActiveLocationsConfigurated(true)
                } else {
                    setAllActiveLocationsConfigurated(false)
                    setbtnMessage(`Configura todas tus tiendas activas para continuar`)
                }
            }
        } else {
            setAllActiveLocationsConfigurated(false)
            setbtnMessage("Debes activar al menos uno para continuar")
        }
    }

    return (
        <div id="locationsConfig" className="content-wrapper">
            <motion.div variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                <h1 className="step-title fw-bold">Tus tiendas</h1>
                <h2 className="step-subtitle">Activa y agrega información extra a tus tiendas para incluir en <span className="fw-bold">{customerName}</span>.</h2>
                <div className="ps-3 mt-4 mb-3 d-flex justify-content-between align-items-center">
                    <InputsSwitch
                        id="all"
                        checked={activationSwitch}
                        onChangeFn={handleActiveAll}
                        labelTxt={activationSwitch ? "Desactivar todas las tiendas" : "Activar todas las tiendas"}
                    />

                    <div className="d-flex justify-content-end align-items-center">
                        {ecommerce === "vtex"
                            &&
                            <ButtonSmall
                                type="button"
                                btnTxt="Crear muelle"
                                icon={<BiPlusCircle/>}
                                showSpinner={false}
                                isDisabled={false}
                                onClickFn={() => history.push(`/${customerRouteName}/vtex${user.insidePanel ? '/panel' : ""}/create-center`)}
                                extraClass="btn-outline-primary create-dock"
                            />
                        }

                        <button className="refresh ms-4" onClick={() => updateLocations()}>
                            {isLoading ?
                                <div className="spinner-grow spinner-grow-sm" role="status">
                                    <span className="visually-hidden">Cargando...</span>
                                </div>
                                :
                                <img src={refresh} alt="refresh" />
                            }
                        </button>
                    </div>
                </div>
                {isLoading ?
                    <>
                        <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
                        <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
                        <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
                        <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
                    </>
                    :
                    <div className="content-locations-list">
                        {showEmptyMsg && <div className="empty-msg"><p className="finish-small-title">Aún no hay tiendas disponibles</p></div>}
                        {ecommerce !== "shopify" ?
                            <>
                                {currentlocations?.locations.map((location, index) => (
                                    <motion.div variants={childVariants} key={index}>
                                        {location.inCoverage ?
                                            <LocationItem
                                                ecommerce={ecommerce}
                                                index={index + 1}
                                                id={location.locationId}
                                                checked={location.active}
                                                handleChange={(e) => handleChange(e, location.locationId, index)}
                                                value
                                                insidePanel={true}
                                                {...location}
                                            />
                                            :
                                            <LocationItemError
                                                ecommerce={ecommerce}
                                                index={index + 1}
                                                id={location.locationId}
                                                insidePanel={true}
                                                {...location}
                                            />
                                        }
                                    </motion.div>

                                ))}
                            </>
                            :
                            <>
                                {currentlocations.locations.map((location, index) => (
                                    <motion.div variants={childVariants} key={index}>
                                        {location.ecommerceConfigured ?
                                            <>
                                                {location.inCoverage ?
                                                    <LocationItem
                                                        ecommerce={ecommerce}
                                                        index={index + 1}
                                                        id={location.locationId}
                                                        checked={location.active}
                                                        handleChange={(e) => handleChange(e, location.locationId, index)}
                                                        value
                                                        insidePanel={true}
                                                        {...location}
                                                    />
                                                    :
                                                    <LocationItemError
                                                        ecommerce={ecommerce}
                                                        index={index + 1}
                                                        id={location.locationId}
                                                        insidePanel={true}
                                                        {...location}
                                                    />
                                                }
                                            </>
                                            :
                                            <LocationItemNotConfiguredInEcommerce
                                                index={index + 1}
                                                {...location}
                                            />
                                        }
                                    </motion.div>
                                ))}
                            </>
                        }
                    </div>
                }
            </motion.div >
            <motion.div className="bottomPage" variants={growBtnSmallDelay} initial="hidden" animate="visible">
                <ButtonSmall
                    ecommerce={ecommerce}
                    type="button"
                    onClickFn={saveNewLocationsConfig}
                    btnTxt={allActiveLocationsConfigurated ? "Guardar" : btnMessage}
                    showSpinner={false}
                    isDisabled={allActiveLocationsConfigurated ? false : true}
                />
            </motion.div>
        </div >
    )
}
