import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { signIn } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { validateInputs } from '../../../services/shared/utils.service';
import { useAuthStore } from '../../../store';
import { ButtonBig } from '../../units/Button';
import { InputsSimple } from '../../units/Inputs';
import { motion } from 'framer-motion';
import { childVariants, growBigBtn } from '../../../services/shared/animation.service';
import { customerRouteName } from '../../../data/InfoSource';
import { setToken } from '../../../services/storage';

export const SignInForm = ({ ecommerce }) => {
    const [isLoading, setIsLoading] = useState("");
    const [formErrors, setFormErrors] = useState({})
    const [body, setBody] = useState({
        storeUrl: "",
        password: ""
    });
    const addAuth = useAuthStore((state) => state.addAuthData);
    const user = useAuthStore((state) => state.authData.user);
    const tenant = customerRouteName;
    let history = useHistory();

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: (e.target.value).trim() })
    }

    const signInVtex = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            try{
                const result = await signIn(body);
                if(result && result.success) {
                   setToken(result.token)
                    addAuth({
                        user: {
                            ...user,
                            storeUrl: body.storeUrl,
                            fullIntegration: result.fullIntegration,
                        }
                    });
                    showSuccess(result.message);

                    const nextStep = result.fullIntegration 
                        ? `/${tenant}/${ecommerce}/panel/order/all`
                        : `/${tenant}/${ecommerce}/panel/location-list`
                    ;
                    history.replace(nextStep);
                }
                setIsLoading(false);
            } catch (error){
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <form onSubmit={signInVtex}>
            <motion.div variants={childVariants} className="mb-2 mb-xxl-3">
                <InputsSimple 
                value={body.storeUrl}
                id="storeUrl"
                type="text"
                placeholder="https://nombredetienda.myvtex.com"
                labelTxt="URL de la tienda de VTEX"
                onChangeFn={handleChange}
                resultValidation = {formErrors?.storeUrl ? "is-invalid" : ""}
                />
                {formErrors.storeUrl && <span className="invalid-msg">{formErrors.storeUrl}</span> }
            </motion.div>

            <motion.div variants={childVariants} className="mb-2 mb-xxl-3">
                <InputsSimple 
                value={body.password}
                id="password"
                type="password"
                placeholder=""
                labelTxt="Contraseña"
                onChangeFn={handleChange}
                resultValidation={formErrors?.password ? "is-invalid" : ""}
                />
                {formErrors.password && <span className="invalid-msg">{formErrors.password}</span> }
            </motion.div>

            <motion.div variants={growBigBtn}>
                <ButtonBig 
                ecommerce={ecommerce}
                type="submit"
                btnTxt="Ingresar"
                showSpinner={isLoading}
                isDisabled={(body.storeUrl && body.password ) ? false : true}
                />
            </motion.div>
        </form>
    )
}
