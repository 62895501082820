import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useAuthStore, useonboardingStore } from "../../store";
import { getLocations, getStoreName } from "../../services/onboarding.service";
import { IntroColumn } from "../../components/onboarding/IntroColumn";
import { LoginForm } from "../../components/onboarding/LoginForm";
import {
  capitalizeFirstLetter,
  setEcommerceURL,
} from "../../services/shared/utils.service";
import { motion } from "framer-motion";
import { fadeInAndUp } from "../../services/shared/animation.service";

import { customerName } from "../../data/InfoSource";
import { ModalCountryFlags } from "../../components/onboarding/ModalCountryFlags";

export const Welcome = () => {
  const location = useLocation();
  const [storeName, setStoreName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const addAuth = useAuthStore((state) => state.addAuthData);
  const user = useAuthStore((state) => state.authData.user);
  const addData = useonboardingStore((state) => state.addData);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
  const { shop = "" } = queryString.parse(location.search);
  const { accessToken = "" } = queryString.parse(location.search);

  useEffect(() => {
    if (user?.insidePanel) {
      addAuth({
        user: {
          ...user,
          insidePanel: false,
        },
      });
    }
    document.title = `${customerName} - ${capitalizeFirstLetter(ecommerce)}`;
  }, []);

  useEffect(() => {
    getInitialStates();
  }, [storeName]);

  const getInitialStates = async () => {
    if (!storeName) {
      setIsLoading(true);
      if (ecommerce === "shopify" && shop) {
        setStoreName(extractStoreName(shop));
        addAuth({
          user: {
            storeId: shop,
            ecommerce: ecommerce,
          },
        });
        const records = await getLocations(shop);
        if (records) {
          addData({
            store: {
              activeAllSwitch: false,
              currentlocations: records,
            },
          });
        }
      }
      if (ecommerce === "tiendanube" && shop) {
        const result = await getStoreName(shop);
        if (result && result.success) {
          setStoreName(result.data.storeName);
          addAuth({
            user: {
              storeId: shop,
              ecommerce: ecommerce,
              storeName: result.data.storeName,
              accessToken: accessToken,
              storeUrl: result.data.storeUrl,
            },
          });
          if (result.data.store) {
            addData({
              store: {
                locations: [
                  {
                    province: result.data.store?.province,
                    city: result.data.store?.city,
                    street: result.data.store?.street,
                    postalCode: result.data.store?.postalCode,
                    externalNumber: result.data.store?.externalNumber,
                    userId: result.data.store?.userId,
                    createAt: result.data.store?.createAt,
                    typeOfFee: result.data.store?.typeOfFee,
                    fee: result.data.store?.fee,
                  },
                ],
              },
            });
          } else {
            addData({
              store: {
                locations: [],
              },
            });
          }
        }
      }
      if (ecommerce === "vtex") {
        addAuth({
          user: {
            ...user,
            storeId: "",
            ecommerce: ecommerce,
          },
        });
      }
      setIsLoading(false);
    }
  };

  const extractStoreName = (fullShopName) => {
    let parts = fullShopName.split(".");
    return parts[0];
  };

  return (
    <div className="container-fluid" id="welcome">
      <div className="row">
        <IntroColumn
          ecommerce={ecommerce}
          storeName={storeName}
          isLoading={isLoading}
        />
        <div className="col col-8 form-col">
          <motion.div
            className="form-wrapper"
            variants={fadeInAndUp}
            initial="hidden"
            animate="visible"
          >
            <h2 className="small-title fw-bold">
              {ecommerce === "vtex"
                ? `Crear cuenta - ${customerName}`
                : `Ingresa tus credenciales de ${customerName}`}
            </h2>
            <p className="small-text">
              Conecta tu cuenta de {customerName} con{" "}
              {capitalizeFirstLetter(ecommerce)}. ¿No conocés tus credenciales?{" "}
              <button
                type="button"
                className="btn-link fw-bold"
                onClick={() => setShowCountryModal(true)}
              >
                Contáctanos.
              </button>
            </p>
            <LoginForm ecommerce={ecommerce} />
          </motion.div>
        </div>
      </div>
      {showCountryModal && ecommerce && (
        <ModalCountryFlags
          ecommerce={ecommerce}
          handleClose={() => setShowCountryModal(false)}
        />
      )}
    </div>
  );
};
