import React from "react";
import { useAuthStore, useonboardingStore } from "../../store";
import { OnboardingHeader } from "../../components/onboarding/OnboardingHeader";
import { ButtonSmall } from "../../components/units/Button";
import { motion } from "framer-motion";
import { customerRouteName } from "../../data/InfoSource";
import {
  fadeInAndRight,
  fadeInAndUp,
  growBtn,
} from "../../services/shared/animation.service";
import finishIllustration from "../../assets/finishIllustration.png";
import { getBaseUrl } from "../../services/shared/rest.service";

export const Finish = () => {
  const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
  const auth = useAuthStore((state) => state.authData);
  const removeOnboardingData = useonboardingStore(
    (state) => state.removeOnboardingData
  );

  const baseUrl = getBaseUrl();

  let ecommerceUrls = {
    shopify: {
      configLink: `https://${auth.user.storeId}/admin/settings/checkout`,
      goToStoreLink: `${baseUrl}/setup?shop=${auth.user.storeId}`,
    },
    tiendanube: {
      configLink: `${auth.user.storeUrl}/admin/preferences/checkout/`,
      goToStoreLink: `${auth.user.storeUrl}/admin`,
    },
    vtex: {
      configLink: ``,
      goToStoreLink: `/${customerRouteName}/vtex/sign-in`,
    },
  };

  const redirect = (url) => {
    window.location.href = `${url}`;
  };

  const btnText = {
    tiendanube: "Volver a mi tienda",
    shopify: "Ir a mi panel de configuraciones",
    vtex: "Iniciar sesión",
  };

  return (
    <div className="container-fluid" id="deliveryPromise">
      <div className="row">
        <div className="col-4 p-0">
          <motion.div
            variants={fadeInAndRight}
            initial="hidden"
            animate="visible"
          >
            <OnboardingHeader
              step={ecommerce === "vtex" ? 2 : 3}
              ecommerce={ecommerce}
            />
          </motion.div>
        </div>
        <div className="col-8 content-wrapper">
          <motion.div
            className="finish-wrapper"
            variants={fadeInAndUp}
            initial="hidden"
            animate="visible"
          >
            <div>
              <h1 className="finish-main-title">
                ¡Listo, guardamos tus datos!
              </h1>
              <h2 className="finish-subtitle">
                Recuerda que siempre puedes volver a editar las configuraciones
                que realizaste en este proceso de instalación.
              </h2>
              {ecommerce === "tiendanube" && (
                <div className="next-steps">
                  <p className="finish-small-title">
                    Para terminar de configurar tu cuenta, debes ingresar como
                    obligatorio el teléfono.
                  </p>
                  <ButtonSmall
                    type="button"
                    btnTxt="Configurar teléfono"
                    showSpinner={false}
                    isDisabled={false}
                    onClickFn={() => {
                      redirect(ecommerceUrls[ecommerce].configLink);
                      removeOnboardingData();
                    }}
                  />
                </div>
              )}
              {ecommerce !== "tiendanube" && (
                <div className="finish-illustration-text">
                  <div className="illustration-wrapper">
                    <img src={finishIllustration} alt="" />
                  </div>
                  <div className="next-steps">
                    {ecommerce === "shopify" && (
                      <p className="finish-small-title">
                        Para terminar de configurar tu cuenta, debes ingresar
                        obligatoriamente el teléfono desde:{" "}
                        <span className="fw-bold">
                          "Configuraciones" &gt; "Pantalla de pago"
                        </span>
                      </p>
                    )}
                    {ecommerce === "vtex" && (
                      <p className="finish-small-title">
                        Para terminar de configurar tu cuenta, debes ingresar a
                        tu <span className="fw-bold">cuenta de VTEX</span> y
                        configurar las{" "}
                        <span className="fw-bold">Políticas de Envío</span>.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </motion.div>
          <motion.div
            className="bottomPage"
            variants={growBtn}
            initial="hidden"
            animate="visible"
          >
            <button
              type="button"
              onClick={() => {
                redirect(ecommerceUrls[ecommerce].goToStoreLink);
                removeOnboardingData();
              }}
              className={`btn-link fw-bold`}
            >
              {btnText[ecommerce]}
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
