import { maxInputLength } from "../../data/InfoSource";

//Formatters

export const setEcommerceURL = (location) => {
  let ecommerce = "";
  if (location.pathname.includes("vtex")) {
    ecommerce = "vtex";
  } else if (location.pathname.includes("secommerce")) {
    ecommerce = "shopify";
  } else if (location.pathname.includes("woo")) {
    ecommerce = "woo";
  } else {
    ecommerce = "tiendanube";
  }
  return ecommerce;
};

export const changeShopifyName = (ecommerce) => {
  if (ecommerce === "shopify") {
    return "secommerce";
  } else {
    return ecommerce;
  }
};

export const capitalizeFirstLetter = (string) => {
  let normalizeWord = string.toLowerCase();
  if (normalizeWord === "vtex") {
    return "VTEX";
  } else {
    return normalizeWord.charAt(0).toUpperCase() + normalizeWord.slice(1);
  }
};

export const formatPhoneNumber = (plainNum) => {
  const regex = /\+{1,}/gi;
  if (!plainNum) return;
  if (regex.test(plainNum)) {
    let cleaner = plainNum.replace(regex, "").trim();
    return `+${cleaner}`;
  }
  return `+${plainNum}`;
};

export const mapFee = (valueNumber) => {
  let separatedValues = {};
  if (valueNumber.toString().includes("-")) {
    const splited = valueNumber.toString().split("");
    const operator = splited.shift();
    const number = splited.join("");
    separatedValues = { operator, number };
  } else {
    separatedValues = { operator: "+", number: valueNumber };
  }
  return separatedValues;
};

export const noSpacedNameShortener = (nameString, finalSliceIndex) => {
  if (!nameString.includes(" ")) {
    return `${nameString.slice(0, finalSliceIndex)}...`;
  }
  return nameString;
};

// Validations

export const validateEmail = (email) => {
  const rgx =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
  return rgx.test(String(email).toLowerCase());
};

export const validatePhone = (phoneNumber) => {
  const re =
    /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;
  return re.test(phoneNumber);
};

export const validateLength = (inputData, maxChars = maxInputLength) => {
  if (String(inputData).length <= maxChars) return true;
};

export const checkBiggerNum = (from, to) => {
  if (Number(from) <= Number(to)) return true;
};

export const checkPercentageLimits = (value) => {
  if (Number(value) >= -100 && Number(value) <= 100) return true;
};

export const validateInputs = (dataObj, setErrorsMethod) => {
  let errors = {};

  for (const property in dataObj) {
    if (!validateLength(dataObj[property])) {
      errors[property] = `No debe superar los ${maxInputLength} caracteres`;
    }
    if (
      property === "email" &&
      dataObj.email &&
      validateLength(dataObj.email)
    ) {
      if (!validateEmail(dataObj.email)) {
        errors.email = "El formato de email es incorrecto";
      }
    }
    if (
      property === "phoneNumber" &&
      dataObj.phoneNumber &&
      validateLength(dataObj.phoneNumber)
    ) {
      if (!validatePhone(dataObj.phoneNumber)) {
        errors.phoneNumber = "El formato de teléfono es incorrecto";
      }
    }
    if (property === "to" && dataObj.to && validateLength(dataObj.to)) {
      if (!checkBiggerNum(dataObj.from, dataObj.to)) {
        errors.to = "El segundo día debe ser igual o mayor al primero";
      }
    }
    if (property === "fee" && dataObj.typeOfFee === "adjusted") {
      if (!checkPercentageLimits(dataObj.fee)) {
        errors.fee = "Debe ser mayor a -100% y menor a 100%";
      }
    }
    if (property === "promoDynFeeAmount") {
      if (!checkPercentageLimits(dataObj.promoDynFeeAmount)) {
        errors.promoDynFeeAmount = "Debe ser mayor a -100% y menor a 100%";
      }
    }
  }
  setErrorsMethod(errors);

  if (Object.keys(errors).length === 0) {
    return true;
  } else {
    return false;
  }
};

// Others

export const getCountryCoords = (countryCode) => {
  const countriesCoords = {
    AR: [-34.61315, -58.37723],
    CL: [-33.45694, -70.64827],
    CO: [4.60971, -74.08175],
    MX: [19.42847, -99.12766],
    UY: [-34.90328, -56.18816],
  };
  return countriesCoords[countryCode];
};
