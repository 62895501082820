import React from "react";
import { useAuthStore } from "../../../store";
import { motion } from "framer-motion";
import {
  fadeInAndUp,
  fadeInAndUpNoDelay,
} from "../../../services/shared/animation.service";
import { useState } from "react";
import { ModalConfirmDeleteAccount } from "../../../components/panel/ModalConfirmDelete";
import { ButtonSmall } from "../../../components/units/Button";

export const Account = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
  const user = useAuthStore((state) => state.authData.user);

  return (
    <div id="account">
      <motion.div
        variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp}
        initial="hidden"
        animate="visible"
        id="account-content"
      >
        <div>
          <h1 className="step-title fw-bold">Cuenta</h1>
          <h2 className="step-subtitle">Eliminar cuenta</h2>
        </div>

        <div className="d-flex justify-content-center warningBox">
          <p>
            Al eliminar tu cuenta, todos tus datos se borrarán de manera
            definitiva y no habrá posibilidad de recuperarlos en el futuro.
          </p>
        </div>

        <div className="d-flex justify-content-center">
          <ButtonSmall
            btnTxt={"Eliminar cuenta"}
            onClickFn={() => setShowConfirmModal(true)}
            extraClass={"fw-bold deleteBtn"}
          />
        </div>
      </motion.div>

      {showConfirmModal && ecommerce && (
        <ModalConfirmDeleteAccount
          ecommerce={ecommerce}
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};
