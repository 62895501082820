import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TableWoo } from './TableWoo';
import { useAuthStore } from '../../store';
import { handleOrdersListWoo } from '../../services/panel.service';
import { showError } from '../../services/shared/alert.service';
import { getOrdersWoo } from '../../services/panel.service';

export const GridSetterWoo = ({ tab, ecommerce }) => {
  const user = useAuthStore((state) => state.authData.user);
  const addAuth = useAuthStore((state) => state.addAuthData);
  const [tabSelected, setTabSelected] = useState('');
  const [ordersFilteredByStatus, setOrdersFilteredByStatus] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  // const [typeOfMerchant, setTypeOfMerchant] = useState("");
  const [rawData, setRawData] = useState([]);
  const [sizePage, setSizePage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    if (tabSelected !== tab) {
      setTabSelected(tab);
      getOrdersList(tab);
    }
  }, []);

  const getOrdersList = async (
    tabToUpdate,
    page,
    perPage,
    search = null,
    start = null,
    end = null
  ) => {
    if (tabToUpdate) {
      setTabSelected(tabToUpdate);
    }
    try {
      setisLoading(true);
      const result = await getOrdersWoo(
        page,
        perPage,
        tabToUpdate,
        search,
        start,
        end
      );
      if (result) {
        setRawData(result);
        setSizePage(result.meta.total);
        filterAndMapOrders(result.data);
        addAuth({
          user: {
            ...user,
            ecommerce,
            insidePanel: true,
          },
        });
      }
      if (ordersFilteredByStatus && ordersFilteredByStatus.length > 0) {
        setisLoading(false);
      }
    } catch (error) {
      showError(error);
    }
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      getOrdersList(tabSelected, page, sizePerPage, null, startDate, endDate);
      setPage(page);
      setPerPage(sizePerPage);
    }
  };

  const afterSearch = (event) => {
    if (event.key === 'Enter') {
      const value = event.target.value;
      getOrdersList(tabSelected, page, perPage, value, startDate, endDate);
    }
  };

  const filterDate = (start, end) => {
    if (start && end) {
      start = new Date(start).toISOString().slice(0, 10);
      end = new Date(end).toISOString().slice(0, 10);
      setStartDate(start);
      setEndDate(end);
      getOrdersList(tabSelected, page, perPage, null, start, end);
    }
  };

  const clearDatesWoo = () => {
    setStartDate(null);
    setEndDate(null);
    getOrdersList(tabSelected, page, perPage, null, null, null);
  };

  const filterAndMapOrders = async (rawData) => {
    setOrdersFilteredByStatus([]);
    setisLoading(true);
    if (rawData && rawData.length > 0) {
      const mappedData = await handleOrdersListWoo(Object.values(rawData), tab);
      setOrdersFilteredByStatus(mappedData);
    }
    setisLoading(false);
  };

  return (
    <div className="grid-table">
      {isLoading ? (
        <>
          <Skeleton count={1} height={70} style={{ marginBottom: '10px' }} />
          <Skeleton count={1} height={30} style={{ marginBottom: '10px' }} />
          <Skeleton count={4} height={50} style={{ marginBottom: '10px' }} />
        </>
      ) : (
        <>
          <TableWoo
            data={ordersFilteredByStatus}
            tabSelected={tabSelected}
            updateTable={getOrdersList}
            ecommerce={ecommerce}
            handleTableChange={handleTableChange}
            page={page}
            perPage={perPage}
            sizePage={sizePage}
            afterSearch={afterSearch}
            filterDate={filterDate}
            startDateWoo={startDate}
            endDateWoo={endDate}
            clearDatesWoo={clearDatesWoo}
          />
        </>
      )}
    </div>
  );
};
