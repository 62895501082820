import React from 'react';
import { countryInfoByEcommerce } from '../../data/InfoSource';


export const ModalCountryFlags = ({ ecommerce, isContact = true, handleClose }) => {

  return (
    <div className='white-backdrop' onClick={handleClose}>
      <div className='flags-content-container'>
        <button type='button' onClick={handleClose} className="closeButton">X</button>
        <h2 className='subtitle'>Selecciona el país</h2>
        <ul className="flags-list">
          {countryInfoByEcommerce[ecommerce].map((country, index) => (
            <li className='flags-item' key={`flag-${index}`}>
              <a href={isContact ? `https://mail.google.com/mail/?view=cm&fs=1&to=${country.contactUs}` : country.createAccount } target={ecommerce !== "shopify" ? "_blank" : "_self"} rel="noreferrer">
                <img src={country.flag} alt={`${country.name}'s flag`} />
                <span>{country.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
