import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { saveDataVtexShopi } from "../../services/onboarding.service";
import { setOrdersSetting } from "../../services/panel.service";
import { showError, showSuccess } from "../../services/shared/alert.service";
import { useAuthStore, useonboardingStore } from "../../store";
import { ButtonSmall } from "../units/Button";
import {
  InputsCheckbox,
  InputsRadio,
  InputsWithTooltip,
} from "../units/Inputs";
import {
  changeShopifyName,
  mapFee,
  validateInputs,
} from "../../services/shared/utils.service";
import { customerRouteName, customerName } from "../../data/InfoSource";
import {
  childVariants,
  fadeInAndUp,
  fadeInAndUpNoDelay,
  growBtn,
  growBtnSmallDelay,
} from "../../services/shared/animation.service";
import { motion } from "framer-motion";

export const DeliveryPromiseForm = ({ ecommerce }) => {
  let history = useHistory();
  const addData = useonboardingStore((state) => state.addData);
  const auth = useAuthStore((state) => state.authData);
  const store = useonboardingStore((state) => state.onboardingData.store);
  const [isLoading, setIsLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [typeOfFee, setTypeOfFee] = useState("");
  const [promoFreeShipping, setPromoFreeShipping] = useState(
    store?.currentlocations?.fee?.promotionalFreeShipping?.active || false
  );
  const [promoDynamicFee, setPromoDynamicFee] = useState(
    store?.currentlocations?.fee?.promotionalDynamicFee?.active || false
  );
  const [body, setBody] = useState({
    fee: "",
    promoFreeShipAmount:
      store?.currentlocations?.fee?.promotionalFreeShipping?.amount || 0,
    promoDynFeeAmount:
      store?.currentlocations?.fee?.promotionalDynamicFee?.amount || 0,
  });
  const [radioValue, setRadioValue] = useState("+");
  const radios = [
    { name: "Descuento", value: "-" },
    { name: "Recargo", value: "+" },
  ];
  const [currentlocations, setCurrentlocations] = useState([]);
  const [promoComplete, setPromoComplete] = useState();

  useEffect(() => {
    setIsLoading(true);
    setCurrentlocations(store?.currentlocations?.locations);
    if (!store?.currentlocations?.fee?.type) {
      setTypeOfFee("free");
      setBody({ ...body, fee: 0 });
    } else {
      setTypeOfFee(store?.currentlocations?.fee?.type);
      if (
        store?.currentlocations?.fee?.type === "dynamic" ||
        store?.currentlocations?.fee?.type === "free"
      ) {
        setBody({ ...body, fee: 0 });
      }
      if (
        store?.currentlocations?.fee?.type === "fixed" &&
        store?.currentlocations?.fee?.amount &&
        store?.currentlocations?.fee?.amount > 0
      ) {
        setBody({ ...body, fee: store.currentlocations.fee.amount });
      }
    }
    if (store?.currentlocations?.fee?.promotionalDynamicFee?.active) {
      const values = mapFee(
        store.currentlocations.fee.promotionalDynamicFee.amount
      );
      setRadioValue(values.operator);
      setBody({ ...body, promoDynFeeAmount: values.number });
    }
    checkPromoValidation();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    checkPromoValidation();
  }, [body, promoFreeShipping, promoDynamicFee]);

  const handleRadioChange = (e) => {
    if (e.target.checked) {
      setTypeOfFee(e.target.value);
    }
    if (e.target.id === "free") {
      setBody({ fee: 0, promoDynFeeAmount: 0, promoFreeShipAmount: 0 });
      setPromoFreeShipping(false);
      setPromoDynamicFee(false);
    }
    if (e.target.id === "dynamic") {
      setBody({ ...body, fee: 0, promoFreeShipAmount: 0 });
      setPromoFreeShipping(false);
    }
    if (e.target.id === "fixed") {
      if (
        store?.currentlocations?.fee?.amount &&
        store?.currentlocations?.fee?.amount > 0
      ) {
        setBody({
          ...body,
          fee: store.currentlocations.fee.amount,
          promoDynFeeAmount: 0,
        });
        setPromoDynamicFee(false);
      } else {
        setBody({ ...body, fee: 0, promoDynFeeAmount: 0 });
        setPromoDynamicFee(false);
      }
    }
  };

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: Number(e.target.value) });
    checkPromoValidation();
  };

  const handleCheckboxes = (e) => {
    if (e.target.id === "promoFreeShipping") {
      setPromoFreeShipping(!promoFreeShipping);
      if (!promoFreeShipping === false) {
        setBody({ ...body, promoFreeShipAmount: 0 });
      }
    }
    if (e.target.id === "promoDynamicFee") {
      setPromoDynamicFee(!promoDynamicFee);
      if (!promoDynamicFee === false) {
        setBody({ ...body, promoDynFeeAmount: 0 });
      }
    }
  };

  const requestBodySetter = () => {
    let composedBody = {
      fee: {
        type: typeOfFee,
        amount: Number(body.fee),
        promotionalFreeShipping: {
          active: promoFreeShipping,
          amount: !promoFreeShipping ? 0 : Number(body.promoFreeShipAmount),
        },
        promotionalDynamicFee: {
          active: promoDynamicFee,
          amount: !promoDynamicFee
            ? 0
            : Number(`${radioValue}${body.promoDynFeeAmount}`),
        },
      },
      locations: [...currentlocations],
    };
    return composedBody;
  };

  const checkPromoValidation = () => {
    if (
      (promoFreeShipping && body.promoFreeShipAmount === 0) ||
      (promoDynamicFee && body.promoDynFeeAmount === 0)
    ) {
      setPromoComplete(false);
    } else {
      setPromoComplete(true);
    }
  };

  const setDeliveryPromise = async (e) => {
    e.preventDefault();
    if (validateInputs(body, setFormErrors)) {
      setIsLoading(true);
      let composedBody = requestBodySetter();
      try {
        await setOrdersSetting(
          {
            configuration: {
              catchAllOrders: false,
              acceptHomeDelivery: true,
              acceptPickitPoints: true,
            },
          },
          auth.user.storeId
        );
        const result = await saveDataVtexShopi(composedBody, auth.user.storeId);
        if (result && result.success) {
          addData({
            store: {
              currentlocations: composedBody,
            },
          });
          showSuccess(result.message);
          if (auth.user.insidePanel) {
            history.push(
              `/${customerRouteName}/${changeShopifyName(ecommerce)}/panel/rate`
            );
          } else {
            history.push(
              `/${customerRouteName}/${changeShopifyName(ecommerce)}/finish`
            );
          }
        }
        if (result?.error) {
          showError(result.error);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showError(error);
      }
    } else {
      return;
    }
  };

  return (
    <form onSubmit={setDeliveryPromise}>
      <motion.div
        className="delivery-promise-rate"
        variants={auth.user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp}
        initial="hidden"
        animate="visible"
      >
        <h2 className="step-title fw-bold">Tarifa</h2>
        <h3 className="step-subtitle">
          Define el tipo de Tarifa que desea aplicar.
        </h3>
        <motion.div className="mt-2 mt-xxl-3" variants={childVariants}>
          <InputsRadio
            id="dynamic"
            name="typeOfFee"
            value="dynamic"
            labelTxt={`Tarifa dinámica de ${customerName}.`}
            onChangeFn={handleRadioChange}
            checked={typeOfFee === "dynamic"}
          />
          <InputsRadio
            id="fixed"
            name="typeOfFee"
            value="fixed"
            labelTxt="Tarifa fija."
            onChangeFn={handleRadioChange}
            checked={typeOfFee === "fixed"}
          />
          {typeOfFee === "fixed" && (
            <div className="feeFixed-phrase-shop">
              <span className="form-check-label">Costo de envío de $</span>
              <InputsWithTooltip
                value={body.fee}
                id="fee"
                type="number"
                onChangeFn={handleChange}
                min={0}
              />
              {formErrors.fee && (
                <span className="invalid-msg">{formErrors.fee}</span>
              )}
            </div>
          )}
          <InputsRadio
            id="free"
            name="typeOfFee"
            value="free"
            labelTxt="Tarifa gratis."
            onChangeFn={handleRadioChange}
            checked={typeOfFee === "free"}
          />
        </motion.div>

        {auth.user.insidePanel && (
          <div className="mt-3 mt-xl-4 mt-xxl-5">
            <h2 className="step-title fw-bold">Tarifas promocionales</h2>
            <h3 className="step-subtitle">
              Defina las configuraciones promocionales.
            </h3>
            <motion.div className="mt-2 mt-xxl-3" variants={childVariants}>
              <InputsCheckbox
                id="promoFreeShipping"
                value={promoFreeShipping}
                checked={promoFreeShipping === true}
                onChangeFn={handleCheckboxes}
                labelTxt="Tarifa de envío gratis por monto del carrito."
                disabled={typeOfFee === "free"} // TODO
              />
              {promoFreeShipping && (
                <div className="promo-phrase-shop">
                  <span className="form-check-label">
                    Quiero aplicar envío gratis a compras superiores a $
                  </span>
                  <InputsWithTooltip
                    value={body.promoFreeShipAmount}
                    id="promoFreeShipAmount"
                    type="number"
                    onChangeFn={handleChange}
                    min={1}
                    disabled={typeOfFee === "free"}
                  />
                  {formErrors.promoFreeShipAmount && (
                    <span className="invalid-msg">
                      {formErrors.promoFreeShipAmount}
                    </span>
                  )}
                </div>
              )}
              <InputsCheckbox
                id="promoDynamicFee"
                value={promoDynamicFee}
                onChangeFn={handleCheckboxes}
                labelTxt="Tarifa dinámica ajustada."
                checked={promoDynamicFee === true}
                disabled={typeOfFee === "fixed" || typeOfFee === "free"}
              />
              {promoDynamicFee && (
                <>
                  <div className="promo-phrase-shop">
                    <span className="form-check-label">Quiero aplicar</span>
                    <InputsWithTooltip
                      value={body.promoDynFeeAmount}
                      id="promoDynFeeAmount"
                      type="number"
                      onChangeFn={handleChange}
                      disabled={typeOfFee === "fixed" || typeOfFee === "free"}
                    />
                    <span className="form-check-label">% de</span>
                    <div className={`radios-wrapper ${ecommerce}`}>
                      {radios.map((radio, idx) => (
                        <div key={idx}>
                          <input
                            id={`radio-${idx}`}
                            type="radio"
                            name="radio"
                            value={radio.value}
                            checked={radio.value === radioValue}
                            onChange={(e) =>
                              setRadioValue(e.currentTarget.value)
                            }
                            className={`btn-check`}
                            disabled={
                              typeOfFee === "fixed" || typeOfFee === "free"
                            }
                          />
                          <label
                            className={`btn btn-outline-custom`}
                            htmlFor={`radio-${idx}`}
                          >
                            {radio.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  {formErrors.promoDynFeeAmount && (
                    <span className="invalid-msg ms-4">
                      {formErrors.promoDynFeeAmount}
                    </span>
                  )}
                </>
              )}
            </motion.div>
          </div>
        )}
      </motion.div>

      <motion.div
        className="bottomPage"
        variants={auth.user.insidePanel ? growBtnSmallDelay : growBtn}
        initial="hidden"
        animate="visible"
      >
        <ButtonSmall
          ecommerce={ecommerce}
          type="submit"
          btnTxt={
            ((typeOfFee === "free" || typeOfFee === "dynamic") &&
              Number(body.fee) === 0) ||
            (typeOfFee === "fixed" && Number(body.fee) > 0)
              ? "Guardar"
              : "Completa todos los datos para continuar"
          }
          showSpinner={isLoading}
          isDisabled={
            ((typeOfFee === "free" || typeOfFee === "dynamic") &&
              Number(body.fee) === 0 &&
              promoComplete) ||
            (typeOfFee === "fixed" && Number(body.fee) > 0 && promoComplete)
              ? false
              : true
          }
        />
      </motion.div>
    </form>
  );
};
